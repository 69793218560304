import { useQuery, useResult } from '@/app/composable';
import { useErrors } from '@/app/composable/errors';
import store from '@/app/store';
import { computed, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { useAsset } from './asset';
import { AssetType } from '../constants';
import GET_ASSETS from '../graphql/assets.graphql';
import GET_LOCATIONS from '../graphql/locations.graphql';
import {
    accessLevelOptions,
    allowedProhibitedOptions,
    audioOptions,
    calculationSchemeOptions,
    currencyOptions,
    derivationOptions,
    imageOptions,
    languageOptions,
    licenseOptions,
    licenses,
    modelOptions,
    paymentMethodOptions,
    requiredOptions,
    resultAccessLevelOptions,
    otherCalculationSchemeOptions,
    otherPaymentMethodOptions,
    shareAlikeOptions,
    spatialCoverageOptions,
    spatialResolutionOptions,
    targetPurposeOptions,
    temporalCoverageOptions,
    temporalResolutionOptions,
    textBinaryOptions,
    textOptions,
    typeOptions,
    videoOptions,
} from '../config/asset';
import { renamings } from '@/app/utilities';



const isFresco: boolean = true;

export function useAssetMetadata(id: number | null) {
    const { checkGQLAuthentication } = useErrors();
    const { assetTypeName } = useAsset();
    const asset = ref<any>(null);
    const accessLevel = ref<any>(null);
    const copyrightOwner = ref<any>(null);
    const tagOptions = ref<any>([]);

    const { result, onError } = useQuery(GET_ASSETS, {}, { fetchPolicy: 'no-cache' });
    onError(checkGQLAuthentication);
    const assets = useResult(result, null, (data: any) => data.myAssets);

    const response = useQuery(GET_LOCATIONS, {}, { fetchPolicy: 'no-cache' });
    const locations = useResult(response.result, null, (data: any) => data.continents);

    const domains = computed(() => store.state.dataModel.domains);
    const spatialIDfields = computed(() => {
        if (!domains.value || !asset.value.structure.domain || !asset.value.structure.domain.uid) return [];
        const domain = domains.value.find(
            (dom: any) =>
                dom.uid === asset.value.structure.domain.uid &&
                dom.majorVersion === asset.value.structure.domain.majorVersion,
        );
        if (!domain) return [];

        const fields = ['uid', 'name'];
        const fieldsResult: any[] = [];
        const extractSpatialIDConcepts = (concepts: any[]) => {
            concepts.forEach((concept: any) => {
                if ('metadata' in concept && concept.metadata) {
                    if ('spatialID' in concept.metadata && concept.metadata.spatialID) {
                        let fieldName = concept.name.replace(/([a-z])([A-Z])/g, '$1 $2');
                        fieldName = fieldName.replace(/([A-Z])([A-Z])([a-z])/g, '$1 $2$3');
                        const spatialField = `Specific ${fieldName.charAt(0).toUpperCase()}${fieldName.substring(1)}`;
                        fieldsResult.push({
                            ...R.pick(fields, concept),
                            spatialField,
                        });
                    }
                }
                if (concept.children) {
                    extractSpatialIDConcepts(concept.children);
                }
            });
        };
        extractSpatialIDConcepts([domain]);
        return fieldsResult;
    });

    const locationOptions = computed(() => {
        const array: any = [{ label: 'Global', id: 'GLOBAL', children: [] }];
        if (locations.value) {
            const sort = R.sortBy(R.prop('name'));
            let continents: any = R.clone(locations.value);
            continents = sort(continents);
            continents.forEach((continent: any) => {
                const children: any = [];
                if (continent.countries) {
                    // eslint-disable-next-line no-param-reassign
                    continent.countries = sort(continent.countries);
                    continent.countries.forEach((country: any) => {
                        children.push({ label: country.name, id: country.code });
                    });
                }
                array[0].children.push({ label: continent.name, id: continent.name.toUpperCase(), children });
            });
        }
        return array;
    });

    const assetType = computed(() => {
        if (asset.value && asset.value.assetTypeId) {
            return assetTypeName(asset.value.assetTypeId);
        }
        return AssetType.Dataset;
    });

    const checkLicense = (license: any) => {
        if (license && license.label !== 'Custom') {
            const licenseMetadata = licenses.find((element) => element.license === license.label);
            if (licenseMetadata) {
                asset.value.metadata.license.license = licenseMetadata.license;
                asset.value.metadata.license.derivation = licenseMetadata.derivation;
                asset.value.metadata.license.attribution = licenseMetadata.attribution;
                asset.value.metadata.license.reproduction = licenseMetadata.reproduction;
                asset.value.metadata.license.distribution = licenseMetadata.distribution;
                asset.value.metadata.license.targetPurpose = licenseMetadata.targetPurpose;
                asset.value.metadata.license.offlineRetention = licenseMetadata.offlineRetention;
                asset.value.metadata.license.reContext = licenseMetadata.reContext;
                asset.value.metadata.license.shareAlike = licenseMetadata.shareAlike;
                asset.value.metadata.license.link = licenseMetadata.link;
            }
        } else if (license && license.label === 'Custom') {
            asset.value.metadata.license.license = 'Custom';
            asset.value.metadata.license.derivation = [];
            asset.value.metadata.license.attribution = null;
            asset.value.metadata.license.reproduction = null;
            asset.value.metadata.license.distribution = null;
            asset.value.metadata.license.targetPurpose = [];
            asset.value.metadata.license.offlineRetention = null;
            asset.value.metadata.license.reContext = null;
            asset.value.metadata.license.shareAlike = null;
            asset.value.metadata.license.link = null;
            asset.value.metadata.pricing.paymentMethod = [AssetType.Result, AssetType.Model].includes(assetType.value)
                ? otherPaymentMethodOptions
                : [];
            asset.value.metadata.pricing.calculationScheme = [AssetType.Result, AssetType.Model].includes(
                assetType.value,
            )
                ? 'Request Dependent'
                : null;
            asset.value.metadata.pricing.cost = null;
            asset.value.metadata.pricing.currency = 'EUR';
        }
    };

    const domainsOptions = computed(() => {
        const options = {};
        let array: any = [];
        if (domains.value) {
            array = domains.value;
        }
        for (let i = 0; i < array.length; i++) {
            options[array[i].id] = array[i].name;
        }
        return options;
    });

    const domainsIdToUID = computed(() =>
        domains.value !== null
            ? domains.value.reduce((list, domain: any) => {
                  return R.assoc(domain.id, R.pick(['uid', 'majorVersion'], domain), list);
              }, {})
            : {},
    );

    const referenceAssets = computed(() => {
        let array = [];
        const options = {};
        if (assets.value && id) {
            array = assets.value.filter(
                (referenceAsset: any) =>
                    referenceAsset.structure &&
                    referenceAsset.structure.type === 'other' &&
                    parseInt(referenceAsset.id, 10) !== id,
            );
            for (let i = 0; i < array.length; i++) {
                options[array[i].id] = array[i].name;
            }
        }
        return options;
    });

    const formatOptions = computed(() => {
        let array: string[] = [];
        if (asset.value.metadata.distribution) {
            switch (asset.value.metadata.distribution.type) {
                case 'Text':
                    array = textOptions;
                    break;
                case 'Text and Binary':
                    array = textBinaryOptions;
                    break;
                case 'Image':
                    array = imageOptions;
                    break;
                case 'Model':
                    array = modelOptions;
                    break;
                case 'Video':
                    array = videoOptions;
                    break;
                case 'Audio':
                    array = audioOptions;
                    break;
                case null:
                default:
                    array = [];
            }
        }
        return array;
    });

    const customLicense = computed(() => {
        if (
            asset.value &&
            asset.value.metadata &&
            asset.value.metadata.license &&
            (asset.value.metadata.license.license === undefined ||
                asset.value.metadata.license.license === null ||
                asset.value.metadata.license.license === 'Custom')
        ) {
            return true;
        }
        return false;
    });

    const spatialIDfieldName = computed(() => {
        if (
            asset.value &&
            asset.value.metadata &&
            asset.value.metadata.extent &&
            asset.value.metadata.extent.spatialCoverage &&
            asset.value.metadata.extent.spatialCoverage.unit &&
            asset.value.metadata.extent.spatialCoverage.unit !== 'Specific Continent/Countries' &&
            asset.value.metadata.extent.spatialCoverage.unit.includes('Specific')
        ) {
            const camelCaseField = asset.value.metadata.extent.spatialCoverage.unit
                .substring(9)
                .split(' ')
                .map((a: string) => a.trim())
                .map((a: string) => a[0].toUpperCase() + a.substring(1))
                .join('');
            let fieldName = camelCaseField;
            if (
                camelCaseField.charAt(0) === camelCaseField.charAt(0).toUpperCase() &&
                camelCaseField.charAt(1) === camelCaseField.charAt(1).toLowerCase()
            ) {
                fieldName = `${camelCaseField.charAt(0).toLowerCase()}${camelCaseField.substring(1)}`;
            }
            return R.pick(
                ['uid', 'name'],
                spatialIDfields.value.find((field: any) => field.name === fieldName),
            );
        }
        return null;
    });

    const temporalFields = computed(() => {
        let object = null;
        if (asset.value.structure && asset.value.structure.temporalFields) {
            object = {};
            asset.value.structure.temporalFields.forEach((field: any) => {
                object[field.uid] = field.name.replaceAll('__', ' > ');
            });
        }
        return object;
    });

    const spatialFields = computed(() => {
        let object = null;
        if (asset.value.structure && asset.value.structure.spatialFields) {
            object = {};
            asset.value.structure.spatialFields.forEach((field: any) => {
                object[field.uid] = field.name.replaceAll('__', ' > ');
            });
        }
        return object;
    });

    /**
     * Creates asset's data structure in tree format
     * @param conceptName the name of a concept
     * @param concept the object of a concept in asset's schema that contains concept's uid and type
     * @param treeStructure the asset's data structure in tree format
     * @param parentIds the ids of the parent concepts of concept
     * @param domainConcepts the concepts of the model in a flat array
     * @param customizedConcepts the customized concepts with their prefixes and descriptions
     */
    const createTreeStructure = (
        conceptName: string,
        concept: any,
        treeStructure: object[],
        parentIds: number[],
        domainConcepts: any,
        customizedConcepts: any,
    ) => {
        let conceptDescription = '';
        // find concept based on its uid in asset's schema
        const domainConcept = domainConcepts.find((conc: any) => conc.uid === concept[`${'_uid'}`]);

        if (domainConcept) {
            const cleanedName = conceptName.split(':')[0]; // in case of anonymization
            // if concept name is not the same then search for related concept
            if (cleanedName !== domainConcept.name && parentIds.length > 0) {
                const referenceConcept = domainConcept.name.charAt(0).toUpperCase() + domainConcept.name.slice(1);

                // first search for related concept with pre-defined prefix
                const relatedConcept = domainConcepts.find(
                    (conc: any) =>
                        conc.referenceConceptId === domainConcept.id &&
                        conc.parentId === parentIds[parentIds.length - 1],
                );
                // if found then search for the correct prefix in reference prefix
                if (relatedConcept) {
                    relatedConcept.referencePrefix.forEach((referencePrefix: any) => {
                        if (`${referencePrefix.prefix}${referenceConcept}` === cleanedName) {
                            conceptDescription = referencePrefix.description;
                        }
                    });
                }

                // otherwise search in customized concepts for related concept with custom prefix
                if (
                    conceptDescription === '' &&
                    Object.keys(customizedConcepts).includes(parentIds[parentIds.length - 1].toString())
                ) {
                    customizedConcepts[parentIds[parentIds.length - 1]].forEach((referencePrefix: any) => {
                        if (`${referencePrefix.prefix}${referenceConcept}` === cleanedName) {
                            conceptDescription = referencePrefix.description;
                        }
                    });
                }
            } else {
                conceptDescription = domainConcept.description;
            }
            parentIds.push(domainConcept.id);
        }
        const field = {
            uid: concept[`${'_uid'}`],
            key: conceptName,
            type: 'object',
            description: conceptDescription,
            children: [],
        };
        const keys = Object.keys(concept);
        // if a concept has children then call function again for each one of them
        if (keys.find((key) => !key.includes('_'))) {
            keys.forEach((key) => {
                if (!key.includes('_')) {
                    createTreeStructure(
                        key,
                        concept[key],
                        field.children,
                        parentIds,
                        domainConcepts,
                        customizedConcepts,
                    );
                    parentIds.pop();
                }
            });
        } else {
            // otherwise set its type
            field.type = concept[`${'_type'}`];
        }
        treeStructure.push(field);
    };

    const getGeneralSchema = computed(() => {
        const items: any[] = [
            {
                type: 'multiselect',
                name: 'tags',
                label: 'Tags',
                placeholder: 'Add tags',
                options: tagOptions.value,
                multiple: true,
                closeOnSelect: false,
                clearOnSelect: false,
                preserveSearch: true,
                preselectFirst: false,
                taggable: true,
                help:
                    'A list of keywords and/or arbitrary textual tags associated with the asset by its data provider.',
                helpPosition: 'before',
                validation: 'required|max:10',
                errorBehavior: 'submit',
                labelClass: ['pb-1'],
            },
            {
                type: 'select',
                name: 'language',
                label: 'Language',
                placeholder: 'Select language',
                options: languageOptions,
                help: 'The language of the data asset.',
                helpPosition: 'before',
                validation: 'required',
                errorBehavior: 'submit',
                inputClass: 'form-select',
                labelClass: ['pb-1'],
                helpClass: ['pb-4'],
            },
        ];
        if (!isFresco && assetType.value === AssetType.Dataset) {
            items.push({
                type: 'select',
                name: 'reference',
                label: 'Reference',
                placeholder: 'Select reference',
                options: referenceAssets.value,
                help: 'The external assets (only Other Files) to which an asset is linked.',
                helpPosition: 'before',
                inputClass: ['bg-red-600', 'form-select', 'leading-6'],
                labelClass: ['pb-1'],
            });
        }
        return items;
    });

    const getDistributionSchema = computed(() => {
        return [
            {
                type: 'radio',
                name: 'type',
                label: 'Type',
                options: typeOptions,
                help: 'The nature or genre of the data asset using a controlled vocabulary.',
                helpPosition: 'before',
                validation: 'required',
                inputClass: 'inline-flex',
                errorBehavior: 'submit',
            },
            {
                component: 'div',
                class: 'grid grid-cols-2 gap-5',
                children: [
                    {
                        type: 'multiselect',
                        name: 'format',
                        label: 'Format',
                        placeholder: 'Select format',
                        options: formatOptions.value,
                        multiple: true,
                        closeOnSelect: false,
                        clearOnSelect: false,
                        preserveSearch: true,
                        preselectFirst: false,
                        taggable: true,
                        help:
                            'The format to which the data will be available through the platform. It is not necessarily the same format with which the data that are uploaded comply.',
                        helpPosition: 'before',
                        validation: 'required|max:10',
                        errorBehavior: 'submit',
                        disabled: !asset.value.metadata.distribution || !asset.value.metadata.distribution.type,
                        labelClass: ['pb-1'],
                        outerClass: ['mb-0'],
                    },
                  
                ],
            },
        ];
    });

    const getTemporalResolutionSchema = computed(() => {
        let schema: any = [];
        let items: any = null;
        if (asset.value.metadata.extent) {
            schema = [
                {
                    component: 'div',
                    class: 'grid grid-cols-2 gap-5',
                    children: [
                        {
                            component: 'div',
                            class: 'col-span-1',
                            children: [
                                {
                                    type: 'select',
                                    name: 'unit',
                                    key: 'temporalResolutionUnit',
                                    label: 'Temporal Resolution Unit',
                                    placeholder: 'Select temporal resolution unit',
                                    options: temporalResolutionOptions,
                                    help:
                                        'The frequency of acquiring new data from the same data source (e.g. as part of a dynamic process from a system/location/sensor).',
                                    helpPosition: 'before',
                                    validation: 'required',
                                    errorBehavior: 'submit',
                                    validationName: 'temporal Resolution Unit',
                                    inputClass: 'form-select w-full',
                                    labelClass: ['pb-1'],
                                },
                            ],
                        },
                    ],
                },
            ];
            if (
                asset.value.metadata.extent.temporalResolution.unit &&
                asset.value.metadata.extent.temporalResolution.unit !== 'Not applicable'
            ) {
                items = [
                    {
                        component: 'div',
                        class: 'col-span-1',
                        children: [
                            {
                                type: 'number',
                                name: 'value',
                                key: 'temporalResolutionValue',
                                label: 'Temporal Resolution',
                                placeholder: 'Enter temporal resolution',
                                help: 'The typical temporal granularity/frequency of the data included in an asset.',
                                helpPosition: 'before',
                                validation: 'required',
                                errorBehavior: 'submit',
                                validationName: 'temporal Resolution',
                                inputClass: 'form-input w-full',
                                labelClass: ['pb-1'],
                            },
                        ],
                    },
                ];
            }
            if (items) {
                Array.prototype.push.apply(schema[0].children, items);
            }
        }

        return schema;
    });

    const getSpatialResolutionSchema = computed(() => {
        return [
            {
                component: 'div',
                class: 'grid grid-cols-2 gap-5',
                children: [
                    {
                        component: 'div',
                        class: 'col-span-1',
                        children: [
                            {
                                type: 'select',
                                name: 'unit',
                                key: 'spatialResolutionUnit',
                                label: 'Spatial Resolution Unit',
                                placeholder: 'Select spatial resolution unit',
                                options: spatialResolutionOptions,
                                help:
                                    'The granularity applied within the data allowing to distinguish different spaces using the data; either in terms of actual space/ground area (e.g. room, zone, building, country etc.) or as defined by a sensor/sensor network.',
                                helpPosition: 'before',
                                validation: 'required',
                                errorBehavior: 'submit',
                                validationName: 'spatial Resolution Unit',
                                inputClass: 'form-select',
                                labelClass: ['pb-1'],
                            },
                        ],
                    },
                ],
            },
        ];
    });

    const getLicensingSchema = computed(() => {
        const schema: any = [];
        let items: any = null;
        if (asset.value.metadata.license) {
            items = [
                {
                    type: 'treeselect',
                    name: 'license',
                    label: 'License',
                    placeholder: 'Select license',
                    clearable: false,
                    disableBranchNodes: true,
                    options: licenseOptions,
                    help:
                        'The legal statement/terms giving official permission to the data asset in a custom manner or according to well-defined data licenses.',
                    helpPosition: 'before',
                    validation: !isFresco ? 'required' : '',
                    errorBehavior: 'submit',
                    labelClass: ['pb-1'],
                    disabled: accessLevel.value === 'Private',
                    input: () => {
                        //
                    },
                    select: checkLicense,
                },
                {
                    type: 'text',
                    name: 'link',
                    label: 'Link',
                    placeholder: 'Enter link',
                    help: 'A link to the exact legal terms of the specific license.',
                    helpPosition: 'before',
                    validation: asset.value.metadata.license.link ? 'url' : null,
                    errorBehavior: 'submit',
                    disabled: !customLicense.value,
                    inputClass: 'form-input',
                    labelClass: ['pb-1'],
                },
                {
                    type: 'checkbox',
                    name: 'derivation',
                    label: 'Derivation',
                    options: derivationOptions,
                    help:
                        'An indication whether the creation and distribution of any update, adaptation, or any other alteration of the asset or of a substantial part of the asset that constitutes a derivative asset is allowed, with permissions to modify, excerpt, annotate, aggregate the original asset.',
                    helpPosition: 'before',
                    inputClass: 'inline-flex',
                    disabled: !customLicense.value,
                },
                {
                    component: 'div',
                    class: 'grid grid-cols-2 gap-5',
                    children: [
                        {
                            type: 'radio',
                            name: 'attribution',
                            label: 'Attribution',
                            options: requiredOptions,
                            help:
                                'An indication whether it is required to give credit to copyright owner and/or asset provider.',
                            helpPosition: 'before',
                            validation: !isFresco ? 'required' : '',
                            errorBehavior: 'submit',
                            inputClass: 'inline-flex',
                            disabled: !customLicense.value,
                        },
                        {
                            type: 'radio',
                            name: 'reproduction',
                            label: 'Reproduction',
                            options: allowedProhibitedOptions,
                            help:
                                'An indication whether from a given asset, temporary or permanent reproductions can be created by any means, in any form, in whole or in part.',
                            helpPosition: 'before',
                            validation: !isFresco ? 'required' : '',
                            errorBehavior: 'submit',
                            inputClass: 'inline-flex',
                            disabled: !customLicense.value,
                        },
                    ],
                },
                {
                    component: 'div',
                    class: 'grid grid-cols-2 gap-5',
                    children: [
                        {
                            type: 'radio',
                            name: 'distribution',
                            label: 'Distribution',
                            options: allowedProhibitedOptions,
                            help:
                                'An indication whether restricted or unrestricted publication and distribution of the asset is allowed.',
                            helpPosition: 'before',
                            validation: !isFresco ? 'required' : '',
                            errorBehavior: 'submit',
                            inputClass: 'inline-flex',
                            disabled: !customLicense.value,
                        },
                        {
                            type: 'radio',
                            name: 'shareAlike',
                            label: 'Share Alike',
                            options: shareAlikeOptions,
                            help:
                                'An indication whether any transformation or building upon the asset requires distribution under the same license.',
                            helpPosition: 'before',
                            validation: !isFresco ? 'required' : '',
                            errorBehavior: 'submit',
                            validationName: 'share Alike',
                            inputClass: 'inline-flex',
                            disabled: !customLicense.value,
                        },
                    ],
                },
                {
                    component: 'div',
                    class: 'grid grid-cols-2 gap-5',
                    children: [
                        {
                            type: 'radio',
                            name: 'reContext',
                            label: 'Re-context',
                            options: allowedProhibitedOptions,
                            help:
                                'An indication whether restricted or unrestricted use of the asset in a different context is allowed.',
                            helpPosition: 'before',
                            validation: !isFresco ? 'required' : '',
                            errorBehavior: 'submit',
                            validationName: 're-context',
                            inputClass: 'inline-flex',
                            disabled: !customLicense.value,
                        },
                        {
                            type: 'radio',
                            name: 'offlineRetention',
                            label: 'Offline Retention',
                            options: allowedProhibitedOptions,
                            help:
                                'An indication whether storage beyond the platform (i.e. local downloading) is permitted for the asset.',
                            helpPosition: 'before',
                            validation: !isFresco ? 'required' : '',
                            errorBehavior: 'submit',
                            validationName: 'offline Retention',
                            inputClass: 'inline-flex',
                            disabled: !customLicense.value,
                        },
                    ],
                },
                {
                    type: 'multiselect',
                    name: 'targetPurpose',
                    label: 'Target Purpose',
                    placeholder: 'Select target purpose',
                    options: targetPurposeOptions,
                    searchable: false,
                    multiple: true,
                    closeOnSelect: false,
                    clearOnSelect: false,
                    help:
                        'The intended use that the data provider allows, i.e. for business purposes, for academic purposes, for scientific purposes, for personal purposes, for non-profit purposes.',
                    helpPosition: 'before',
                    validation: !isFresco ? 'required' : '',
                    validationName: 'target Purpose',
                    errorBehavior: 'submit',
                    disabled: !customLicense.value,
                    labelClass: ['pb-1'],
                },
            ];
            Array.prototype.push.apply(schema, items);
        }
        return schema;
    });

    const getPricingSchema = computed(() => {
        let schema: any = [];
        let items: any = null;
        if (asset.value.metadata.pricing) {
            schema = [
                {
                    type: 'multiselect',
                    name: 'paymentMethod',
                    label: 'Payment Method',
                    placeholder: 'Select payment method',
                    options: [AssetType.Result, AssetType.Model].includes(assetType.value)
                        ? otherPaymentMethodOptions
                        : paymentMethodOptions,
                    searchable: false,
                    multiple: true,
                    closeOnSelect: false,
                    clearOnSelect: false,
                    help: `The applicable payment method that the data provider has defined in order for the payment to be conducted ${
                        [AssetType.Result, AssetType.Model].includes(assetType.value)
                            ? 'online through crypto-currency'
                            : '“offline” (outside the platform), e.g. credit/debit card, bank transfer, online payment services'
                    }.`,
                    helpPosition: 'before',
                    validation: 'required',
                    validationName: 'payment Method',
                    errorBehavior: 'submit',
                    labelClass: ['pb-2'],
                },
                {
                    component: 'div',
                    class: 'grid grid-cols-2 gap-5',
                    children: [
                        {
                            type: 'select',
                            name: 'calculationScheme',
                            label: 'Calculation Scheme',
                            placeholder: 'Select calculation scheme',
                            options: [AssetType.Result, AssetType.Model].includes(assetType.value)
                                ? otherCalculationSchemeOptions
                                : calculationSchemeOptions,
                            help: `The applicable cost calculation scheme for the asset that ${
                                [AssetType.Result, AssetType.Model].includes(assetType.value)
                                    ? 'is always'
                                    : 'may range from fixed per row and fixed per asset to'
                            } request dependent.`,

                            helpPosition: 'before',
                            validation: 'required',
                            errorBehavior: 'submit',
                            validationName: 'calculation Scheme',
                            inputClass: 'form-select',
                            labelClass: ['pb-2'],
                            outerClass: ['mb-0'],
                        },
                    ],
                },
            ];
            if (asset.value.metadata.pricing.calculationScheme !== 'Request Dependent') {
                items = [
                    {
                        type: 'group',
                        name: 'costCurrency',
                        label: 'Cost',
                        help:
                            'The price for the acquisition of the asset including its currency, if it is fixed per asset.',
                        helpPosition: 'before',
                        children: [
                            {
                                component: 'div',
                                class: 'grid grid-cols-3 gap-5',
                                children: [
                                    {
                                        component: 'div',
                                        class: 'col-span-2',
                                        children: [
                                            {
                                                type: 'number',
                                                name: 'cost',
                                                placeholder: 'Enter cost',
                                                validation: 'required',
                                                errorBehavior: 'submit',
                                                inputClass: 'form-input',
                                            },
                                        ],
                                    },
                                    {
                                        component: 'div',
                                        class: 'col-span-1',
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'currency',
                                                placeholder: 'Select currency',
                                                options: currencyOptions,
                                                validation: 'required',
                                                errorBehavior: 'submit',
                                                inputClass: 'form-select',
                                                value: 'EUR',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ];
                Array.prototype.push.apply(schema[1].children, items);
            }
        }

        return schema;
    });

    const initAsset = (blocks: {
        general: boolean;
        distribution: boolean;
        extent: boolean;
        licensing: boolean;
        pricing: boolean;
    }) => {
        const emptyAsset: any = {
            name: null,
            description: null,
            status: null,
            metadata: {},
        };

        if (blocks.general) {
            emptyAsset.metadata.general = {
                tags: [],
                reference: null,
            };
        }
        if (blocks.distribution) {
            emptyAsset.metadata.distribution = {
                type: null,
                format: [],
                language: null,
            };
        }
        if (blocks.extent) {
            emptyAsset.metadata.extent = {
                temporalCoverage: {
                    type: null,
                    field: null,
                    value: null,
                    min: null,
                    max: null,
                    unit: null,
                    timePeriod: [{ min: null, max: null }],
                },
                spatialCoverage: {
                    type: null,
                    value: null,
                    values: [],
                    coordinates: {
                        lat: null,
                        lon: null,
                    },
                    field: null,
                    unit: null,
                    exactLocation: [{ lat: null, lon: null }],
                },
                temporalResolution: {
                    value: null,
                    unit: null,
                },
                spatialResolution: {
                    unit: null,
                },
            };
        }
        if (blocks.licensing) {
            emptyAsset.metadata.license = {
                accessLevel: null,
                license: null,
                copyrightOwner: null,
                link: null,
                derivation: [],
                attribution: null,
                reproduction: null,
                distribution: null,
                shareAlike: null,
                reContext: null,
                offlineRetention: null,
                targetPurpose: [],
            };
        }
        if (blocks.pricing) {
            emptyAsset.metadata.pricing = {
                cost: null,
                currency: 'EUR',
                paymentMethod: [],
                calculationScheme: null,
            };
        }
        return emptyAsset;
    };

    const mergeAsset = (emptyAsset: any, databaseAsset: any) => {
        // eslint-disable-next-line no-param-reassign
        databaseAsset.metadata = { ...emptyAsset.metadata, ...databaseAsset.metadata };
        const mergedAsset = { ...emptyAsset, ...databaseAsset };
        if (mergedAsset.metadata) {
            if (mergedAsset.metadata.general) {
                if (mergedAsset.metadata.general.tags) {
                    tagOptions.value = R.clone(mergedAsset.metadata.general.tags);
                }
                if (mergedAsset.metadata.general.reference) {
                    mergedAsset.metadata.general.reference = mergedAsset.metadata.general.reference.id.toString();
                }
            }
            if (mergedAsset.metadata.license) {
                if (
                    mergedAsset.metadata.license.derivation &&
                    mergedAsset.metadata.license.derivation.length === 1 &&
                    mergedAsset.metadata.license.derivation[0] === 'Prohibited'
                ) {
                    mergedAsset.metadata.license.derivation = [];
                }
                if (mergedAsset.metadata.license.accessLevel) {
                    accessLevel.value = mergedAsset.metadata.license.accessLevel;
                }
                if (mergedAsset.metadata.license.copyrightOwner) {
                    copyrightOwner.value = mergedAsset.metadata.license.copyrightOwner;
                }
            }
            if (mergedAsset.metadata.extent) {
                // for when a mapped field used in temporal coverage is removed in revised mapping
                if (mergedAsset.metadata.extent.temporalCoverage === null) {
                    mergedAsset.metadata.extent.temporalCoverage = R.clone(emptyAsset.metadata.extent.temporalCoverage);
                }
                if (
                    mergedAsset.metadata.extent.temporalCoverage &&
                    mergedAsset.metadata.extent.temporalCoverage.field
                ) {
                    mergedAsset.metadata.extent.temporalCoverage.field =
                        mergedAsset.metadata.extent.temporalCoverage.field.uid;
                }
                // for when a mapped field used in spatial coverage coverage is removed in revised mapping
                if (mergedAsset.metadata.extent.spatialCoverage === null) {
                    mergedAsset.metadata.extent.spatialCoverage = R.clone(emptyAsset.metadata.extent.spatialCoverage);
                }
                if (mergedAsset.metadata.extent.spatialCoverage && mergedAsset.metadata.extent.spatialCoverage.field) {
                    mergedAsset.metadata.extent.spatialCoverage.field =
                        mergedAsset.metadata.extent.spatialCoverage.field.uid;
                }
                if (
                    mergedAsset.metadata.extent.spatialCoverage &&
                    mergedAsset.metadata.extent.spatialCoverage.coordinates
                ) {
                    const { lat } = mergedAsset.metadata.extent.spatialCoverage.coordinates;
                    const { lon } = mergedAsset.metadata.extent.spatialCoverage.coordinates;
                    mergedAsset.metadata.extent.spatialCoverage.exactLocation = [{ lat, lon }];
                }
                if (
                    mergedAsset.metadata.extent.temporalCoverage &&
                    mergedAsset.metadata.extent.temporalCoverage.unit === 'Time Period' &&
                    mergedAsset.metadata.extent.temporalCoverage.max &&
                    mergedAsset.metadata.extent.temporalCoverage.min
                ) {
                    const { min } = mergedAsset.metadata.extent.temporalCoverage;
                    const { max } = mergedAsset.metadata.extent.temporalCoverage;
                    mergedAsset.metadata.extent.temporalCoverage.timePeriod = [{ min, max }];
                }
                if (
                    mergedAsset.metadata.pricing &&
                    mergedAsset.metadata.pricing.cost !== null &&
                    mergedAsset.metadata.pricing.currency
                ) {
                    const cost = mergedAsset.metadata.pricing.cost.toString();
                    const { currency } = mergedAsset.metadata.pricing;
                    mergedAsset.metadata.pricing.costCurrency = [{ cost, currency }];
                }
            }
        }
        asset.value = mergedAsset;
    };

    watch(
        () => asset.value && asset.value.metadata.distribution && asset.value.metadata.distribution.type,
        (type, oldType) => {
            if (oldType && oldType !== type) {
                asset.value.metadata.distribution.format = [];
            }
        },
    );

    watch(
        () =>
            asset.value &&
            asset.value.metadata.extent &&
            asset.value.metadata.extent.temporalResolution &&
            asset.value.metadata.extent.temporalResolution.unit,
        (unit, oldUnit) => {
            if (oldUnit && oldUnit !== unit && unit === 'Not applicable') {
                asset.value.metadata.extent.temporalResolution.value = null;
            }
        },
    );

    watch(
        () => asset.value && spatialIDfields.value,
        (fields) => {
            if (fields) {
                fields.forEach((field: any) => {
                    const keys = Object.keys(spatialCoverageOptions);
                    if (!keys.includes(field.spatialField)) {
                        spatialCoverageOptions[field.spatialField] = field.spatialField;
                    }
                });
            }
        },
    );

    watch(
        () => asset.value && asset.value.structure && asset.value.structure.schema,
        (schema) => {
            const temporalKeys = Object.keys(temporalCoverageOptions);
            const spatialKeys = Object.keys(spatialCoverageOptions);
            if (assetType.value === AssetType.Dataset) {
                if (schema !== null) {
                    if (
                        asset.value.structure.temporalFields &&
                        asset.value.structure.temporalFields.length !== 0 &&
                        !temporalKeys.includes('Calculated based on data')
                    ) {
                        temporalCoverageOptions['Calculated based on data'] = 'Calculated based on data';
                    }
                    if (
                        asset.value.structure.spatialFields &&
                        asset.value.structure.spatialFields.length !== 0 &&
                        !spatialKeys.includes('Calculated based on data')
                    ) {
                        spatialCoverageOptions['Calculated based on data'] = 'Calculated based on data';
                    }
                } else {
                    if (temporalKeys.includes('Calculated based on data')) {
                        delete temporalCoverageOptions['Calculated based on data'];
                    }
                    if (spatialKeys.includes('Calculated based on data')) {
                        delete spatialCoverageOptions['Calculated based on data'];
                    }
                }
            }
        },
    );

    watch(
        () =>
            asset.value &&
            asset.value.metadata.extent &&
            asset.value.metadata.extent.spatialCoverage &&
            asset.value.metadata.extent.spatialCoverage.unit,
        (unit, oldUnit) => {
            if (oldUnit && oldUnit !== unit) {
                asset.value.metadata.extent.spatialCoverage = {
                    type: null,
                    value: null,
                    values: [],
                    coordinates: {
                        lat: null,
                        lon: null,
                    },
                    field: null,
                    unit,
                    exactLocation: [{ lat: null, lon: null }],
                };
            }
        },
    );

    watch(
        () =>
            asset.value &&
            asset.value.metadata.extent &&
            asset.value.metadata.extent.temporalCoverage &&
            asset.value.metadata.extent.temporalCoverage.unit,
        (unit, oldUnit) => {
            if (oldUnit && oldUnit !== unit) {
                asset.value.metadata.extent.temporalCoverage = {
                    type: null,
                    field: null,
                    value: null,
                    min: null,
                    max: null,
                    unit,
                    timePeriod: [{ min: null, max: null }],
                };
            }
        },
    );

    return {
        asset,
        accessLevel,
        copyrightOwner,
        domainsIdToUID,
        domainsOptions,
        locationOptions,
        accessLevelOptions,
        resultAccessLevelOptions,
        referenceAssets,
        spatialIDfieldName,
        customLicense,
        temporalFields,
        spatialFields,
        createTreeStructure,
        getGeneralSchema,
        getDistributionSchema,
        getTemporalResolutionSchema,
        getSpatialResolutionSchema,
        getLicensingSchema,
        getPricingSchema,
        initAsset,
        mergeAsset,
        checkLicense,
        temporalCoverageOptions,
        spatialCoverageOptions,
        domains,
        assetType,
    };
}
