/* eslint-disable no-param-reassign */
import { useSSE } from '@/app/composable';
import { SseQueue } from '@/app/constants';
import { ExecutionStatusWrapper, ExecutionType, MessageType } from '@/modules/workflow-designer/constants';
import { EventMessage } from '@/modules/workflow-designer/types';
import { computed, onBeforeUnmount, ref, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import {
    accessLevelOptions,
    allowedProhibitedOptions,
    currencyOptions,
    derivationOptions,
    licenseOptions,
    licenses,
    otherCalculationSchemeOptions,
    otherPaymentMethodOptions,
    requiredOptions,
    shareAlikeOptions,
    targetPurposeOptions,
} from '../config/asset';
import { KerasStructure, MLLIbStructure, SKLearnStructure } from '../constants';
import { S } from '@/app/utilities';

export function useModelRegistration(asset: Ref<any>, readOnly: Ref<boolean> = ref(false)) {
    const accessLevel = ref<any>(null);
    const copyrightOwner = ref<any>(null);

    const checkLicense = (license: any) => {
        if (license && license.label !== 'Custom') {
            const licenseMetadata = licenses.find((element) => element.license === license.label);
            if (licenseMetadata) {
                asset.value.metadata.license.license = licenseMetadata.license;
                asset.value.metadata.license.link = licenseMetadata.link;
            }
        } else if (license && license.label === 'Custom') {
            asset.value.metadata.license.license = 'Custom';
            asset.value.metadata.license.shareAlike = null;
            asset.value.metadata.license.link = null;
            asset.value.metadata.pricing.paymentMethod = otherPaymentMethodOptions;
            asset.value.metadata.pricing.calculationScheme = 'Request Dependent';
            asset.value.metadata.pricing.cost = null;
            asset.value.metadata.pricing.currency = 'EUR';
        }
    };

    const customLicense = computed(() => {
        if (
            asset.value &&
            asset.value.metadata &&
            asset.value.metadata.license &&
            (asset.value.metadata.license.license === undefined ||
                asset.value.metadata.license.license === null ||
                asset.value.metadata.license.license === 'Custom')
        ) {
            return true;
        }
        return false;
    });

    const getLicensingSchema = computed(() => {
        const schema: any = [];
        let items: any = null;
        if (asset.value.metadata.license) {
            items = [
                {
                    type: 'treeselect',
                    name: 'license',
                    label: 'License',
                    placeholder: 'Select license',
                    clearable: false,
                    disableBranchNodes: true,
                    options: licenseOptions,
                    help:
                        'The legal statement/terms giving official permission to the asset in a custom manner or according to well-defined data licenses.',
                    helpPosition: 'before',
                    validation: 'required',
                    errorBehavior: 'submit',
                    labelClass: ['pb-1'],
                    disabled: accessLevel.value === 'Private' || readOnly.value,
                    input: () => {
                        //
                    },
                    select: checkLicense,
                },
                {
                    type: 'text',
                    name: 'link',
                    label: 'Link',
                    placeholder: 'Enter link',
                    help: 'A link to the exact legal terms of the specific license.',
                    helpPosition: 'before',
                    validation: asset.value.metadata.license.link ? 'url' : null,
                    errorBehavior: 'submit',
                    disabled: !customLicense.value || readOnly.value,
                    inputClass: 'form-input',
                    labelClass: ['pb-1'],
                },
                {
                    type: 'checkbox',
                    name: 'derivation',
                    label: 'Derivation',
                    options: derivationOptions,
                    help:
                        'An indication whether the creation and distribution of any update, adaptation, or any other alteration of the asset or of a substantial part of the asset that constitutes a derivative asset is allowed, with permissions to modify, excerpt, annotate, aggregate the original asset.',
                    helpPosition: 'before',
                    inputClass: 'inline-flex',
                    disabled: !customLicense.value,
                },
                {
                    component: 'div',
                    class: 'grid grid-cols-2 gap-5',
                    children: [
                        {
                            type: 'radio',
                            name: 'attribution',
                            label: 'Attribution',
                            options: requiredOptions,
                            help:
                                'An indication whether it is required to give credit to copyright owner and/or asset provider.',
                            helpPosition: 'before',
                            validation: 'required',
                            errorBehavior: 'submit',
                            inputClass: 'inline-flex',
                            disabled: !customLicense.value,
                        },
                        {
                            type: 'radio',
                            name: 'reproduction',
                            label: 'Reproduction',
                            options: allowedProhibitedOptions,
                            help:
                                'An indication whether from a given asset, temporary or permanent reproductions can be created by any means, in any form, in whole or in part.',
                            helpPosition: 'before',
                            validation: 'required',
                            errorBehavior: 'submit',
                            inputClass: 'inline-flex',
                            disabled: !customLicense.value,
                        },
                    ],
                },
                {
                    component: 'div',
                    class: 'grid grid-cols-2 gap-5',
                    children: [
                        {
                            type: 'radio',
                            name: 'distribution',
                            label: 'Distribution',
                            options: allowedProhibitedOptions,
                            help:
                                'An indication whether restricted or unrestricted publication and distribution of the asset is allowed.',
                            helpPosition: 'before',
                            validation: 'required',
                            errorBehavior: 'submit',
                            inputClass: 'inline-flex',
                            disabled: !customLicense.value,
                        },
                        {
                            type: 'radio',
                            name: 'shareAlike',
                            label: 'Share Alike',
                            options: shareAlikeOptions,
                            help:
                                'An indication whether any transformation or building upon the asset requires distribution under the same license.',
                            helpPosition: 'before',
                            validation: 'required',
                            errorBehavior: 'submit',
                            validationName: 'share Alike',
                            inputClass: 'inline-flex',
                            disabled: !customLicense.value,
                        },
                    ],
                },
                {
                    type: 'multiselect',
                    name: 'targetPurpose',
                    label: 'Target Purpose',
                    placeholder: 'Select target purpose',
                    options: targetPurposeOptions,
                    searchable: false,
                    multiple: true,
                    closeOnSelect: false,
                    clearOnSelect: false,
                    help:
                        'The intended use that the data provider allows, i.e. for business purposes, for academic purposes, for scientific purposes, for personal purposes, for non-profit purposes.',
                    helpPosition: 'before',
                    validation: 'required',
                    validationName: 'target Purpose',
                    errorBehavior: 'submit',
                    disabled: !customLicense.value,
                    labelClass: ['pb-1'],
                },
            ];
            Array.prototype.push.apply(schema, items);
        }
        return schema;
    });

    const getPricingSchema = computed(() => {
        let schema: any = [];
        let items: any = null;
        if (asset.value.metadata.pricing) {
            schema = [
                {
                    type: 'multiselect',
                    name: 'paymentMethod',
                    label: 'Payment Method',
                    placeholder: 'Select payment method',
                    options: otherPaymentMethodOptions,
                    searchable: false,
                    multiple: true,
                    closeOnSelect: false,
                    clearOnSelect: false,
                    help:
                        'The applicable payment method that the data provider has defined in order for the payment to be conducted online through crypto-currency',
                    helpPosition: 'before',
                    validation: 'required',
                    validationName: 'payment Method',
                    errorBehavior: 'submit',
                    labelClass: ['pb-2'],
                    disabled: true,
                },
                {
                    component: 'div',
                    class: 'grid grid-cols-2 gap-5',
                    children: [
                        {
                            type: 'select',
                            name: 'calculationScheme',
                            label: 'Calculation Scheme',
                            placeholder: 'Select calculation scheme',
                            options: otherCalculationSchemeOptions,
                            help:
                                'The applicable cost calculation scheme for the asset that is always request dependent.',
                            helpPosition: 'before',
                            validation: 'required',
                            errorBehavior: 'submit',
                            validationName: 'calculation Scheme',
                            inputClass: 'form-select',
                            labelClass: ['pb-2'],
                            outerClass: ['mb-0'],
                            disabled: readOnly.value,
                        },
                    ],
                },
            ];
            if (asset.value.metadata.pricing.calculationScheme !== 'Request Dependent') {
                items = [
                    {
                        type: 'group',
                        name: 'costCurrency',
                        label: 'Cost',
                        help:
                            'The price for the acquisition of the asset including its currency, if it is fixed per asset.',
                        helpPosition: 'before',
                        children: [
                            {
                                component: 'div',
                                class: 'grid grid-cols-3 gap-5',
                                children: [
                                    {
                                        component: 'div',
                                        class: 'col-span-2',
                                        children: [
                                            {
                                                type: 'number',
                                                name: 'cost',
                                                placeholder: 'Enter cost',
                                                validation: 'required',
                                                errorBehavior: 'submit',
                                                inputClass: 'form-input',
                                                disabled: readOnly.value,
                                            },
                                        ],
                                    },
                                    {
                                        component: 'div',
                                        class: 'col-span-1',
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'currency',
                                                placeholder: 'Select currency',
                                                options: currencyOptions,
                                                validation: 'required',
                                                errorBehavior: 'submit',
                                                inputClass: 'form-select',
                                                value: 'EUR',
                                                disabled: readOnly.value,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ];
                Array.prototype.push.apply(schema[1].children, items);
            }
        }

        return schema;
    });

    const initAsset = (blocks: {
        general: boolean;
        distribution: boolean;
        extent: boolean;
        licensing: boolean;
        pricing: boolean;
        model: boolean;
    }) => {
        const emptyAsset: any = {
            name: null,
            description: null,
            status: null,
            metadata: {},
            policies: [],
            assetTypeId: 3,
        };

        if (blocks.general) {
            emptyAsset.metadata.general = {
                tags: [],
                reference: null,
            };
        }
        if (blocks.distribution) {
            emptyAsset.metadata.distribution = {
                type: null,
                format: [],
                language: null,
            };
        }
        if (blocks.licensing) {
            emptyAsset.metadata.license = {
                accessLevel: null,
                license: null,
                copyrightOwner: null,
                link: null,
                derivation: [],
                attribution: null,
                reproduction: null,
                distribution: null,
                shareAlike: null,
                reContext: null,
                offlineRetention: null,
                targetPurpose: [],
            };
        }
        if (blocks.pricing) {
            emptyAsset.metadata.pricing = {
                cost: null,
                currency: 'EUR',
                paymentMethod: [],
                calculationScheme: null,
            };
        }

        if (blocks.model) {
            emptyAsset.metadata.model = {
                library: '',
                type: '',
                purpose: '',
                name: '',
                algorithm: '',
                featureOrder: [],
                encodedFeatures: [],
            };
        }
        return emptyAsset;
    };

    const modelStructure = ref<any>();

    const showModelValidationModal = ref<boolean>(false);
    const modelValidationStatus = ref<string>('');
    const canCloseModal = ref<boolean>(false);

    const librarySelected = async (event: any) => {
        switch (event) {
            case 'keras':
                modelStructure.value = KerasStructure;
                break;
            case 'mllib':
                modelStructure.value = MLLIbStructure;
                break;
            case 'sklearn':
            case 'xgboost':
            case 'statsmodel':
                modelStructure.value = SKLearnStructure;
                break;
            default:
                modelStructure.value = [];
                break;
        }
    };

    const modalImage = computed(() => {
        switch (modelValidationStatus.value) {
            case 'uploading':
                return '/img/file_sync.svg';
            case 'running':
                return '/img/validating.svg';
            case 'completed':
                return '/img/success.svg';
            case 'failed':
            case 'invalid':
                return '/img/fail.svg';
            default:
                return '/img/validating.svg';
        }
    });

    const modalTitle = computed(() => {
        switch (modelValidationStatus.value) {
            case 'uploading':
                return 'Uploading model and sample data for validation';
            case 'running':
                return 'Validating model using sample data';
            case 'completed':
                return 'Model Validation Success!';
            case 'failed':
            case 'invalid':
                return 'Model Validation Failed!';
            default:
                return 'Validating model using sample data';
        }
    });

    const modalDescription = computed(() => {
        switch (modelValidationStatus.value) {
            case 'uploading':
                return 'Uploading model and sample data for validation. If the model provided is large, this may take a while. The sample file is cropped to the first 50 rows.';
            case 'running':
                return 'Model and sample data successfully uploaded. The model is now being validated by applying it to the sample data provided.';
            case 'completed':
                return 'Your model is successfully validated. You can fill-in the remaining forms and complete the registration!';
            case 'failed':
                return 'Your model could not be validated. Make sure you have filled in the correct model details, including model library, type, purspose and the model features in the order they were trained.';
            case 'invalid':
                return 'Your model directory structure is invalid. Make sure you follow the specified directory structure before zipping your model.';

            default:
                return 'Validating model using sample data';
        }
    });

    const modelSourceOptions = {
        Upload: 'Upload new trained model',
        Platform: 'Trained on the platform',
    };

    const modelLibraryOptions = {
        mllib: 'MLlib',
        sklearn: 'Scikit-learn',
        xgboost: 'XGBoost',
        statsmodel: 'Statsmodel',
        keras: 'Keras (Tensorflow)',
        pmdarima: 'Pmdarima',
    };

    const modelTypeOptions = computed(() => {
        if (asset.value && asset.value.metadata && asset.value.metadata.model) {
            if (asset.value.metadata.model.library === 'sklearn' || asset.value.metadata.model.library === 'mllib') {
                return {
                    model: 'Model',
                    transformer: 'Transformer (encoder/scaler)',
                    pipeline: 'Pipeline (transformers & model)',
                };
            }
            if (asset.value.metadata.model.library !== '') {
                return { model: 'Model' };
            }
        }
        return {};
    });

    const modelPurposeOptionsObject = {
        modelOrPipeline: {
            mllib: {
                classification: 'Classification',
                regression: 'Regression',
                clustering: 'Clustering',
            },
            sklearn: {
                classification: 'Classification',
                regression: 'Regression',
                clustering: 'Clustering',
                'unsupervised outlier detection': 'Outlier Detection',
            },
            xgboost: {
                classification: 'Classification',
                regression: 'Regression',
            },
            statsmodel: {
                'timeseries forecasting': 'Timeseries Analysis (arima, sarimax)',
                'timeseries forecasting var': 'Timeseries Analysis (VAR)',
            },
            keras: {
                'deep learning': 'Deep Learning',
            },
            pmdarima: {
                'timeseries forecasting': 'Timeseries Analysis',
            },
        },
        transformer: {
            mllib: {
                'One Hot Encoding': 'One Hot Encoding',
                'String Indexing': 'String Indexing',
                'MinMax Scaling': 'MinMax Scaling',
                'Standard Scaling': 'Standard Scaling',
                'Dimensionality Reduction': 'Dimensionality Reduction',
            },
            sklearn: {
                'One Hot Encoding': 'One Hot Encoding',
                'Label Encoding': 'Label Encoding',
                'Ordinal Encoding': 'Ordinal Encoding',
                'MinMax Scaling': 'MinMax Scaling',
                'Standard Scaling': 'Standard Scaling',
                'Dimensionality Reduction': 'Dimensionality Reduction',
            },
        },
    };

    const modelPurposeOptions = computed(() => {
        if (asset.value && asset.value.metadata && asset.value.metadata.model) {
            const type = asset.value.metadata.model.type === 'transformer' ? 'transformer' : 'modelOrPipeline';
            if (S.has(asset.value.metadata.model.library, modelPurposeOptionsObject[type])) {
                return modelPurposeOptionsObject[type][asset.value.metadata.model.library];
            }
        }
        return {};
    });

    // HANDLE SSE
    const { initialise: initSSE, destroy: destroySSE } = useSSE();
    const runningExecution = ref<string>();

    const onMessage = async (data: EventMessage) => {
        // Handle the case where we have an execution status change
        if (
            data.type === MessageType.Status &&
            Object.values(ExecutionType).includes(data.body.executionType as ExecutionType)
        ) {
            // Figure out if the currently running execution has completed
            if (!R.isNil(runningExecution.value) && !R.isNil(data.body.status)) {
                if (
                    data.executionId === runningExecution.value &&
                    ExecutionStatusWrapper.finishedStatuses().includes(data.body.status)
                ) {
                    modelValidationStatus.value = data.body.status;
                    canCloseModal.value = true;
                }
            }
        }
    };

    const listenToWorkflow = (workflowId: string, executionId: string) => {
        runningExecution.value = executionId;
        initSSE(`/api/workflow/${workflowId}/sse`, SseQueue.Workflow, onMessage);
    };

    onBeforeUnmount(() => {
        destroySSE();
    });

    return {
        asset,
        accessLevel,
        copyrightOwner,
        accessLevelOptions,
        customLicense,
        getLicensingSchema,
        getPricingSchema,
        initAsset,
        checkLicense,
        modelSourceOptions,
        modelTypeOptions,
        modelPurposeOptions,
        modelLibraryOptions,
        listenToWorkflow,
        showModelValidationModal,
        modelValidationStatus,
        canCloseModal,
        librarySelected,
        modalImage,
        modalTitle,
        modalDescription,
        modelStructure,
    };
}
