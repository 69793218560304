
























































































































import * as R from 'ramda';
import { defineComponent, computed, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import dayjs from 'dayjs';
import { useAxios } from '@vue-composable/axios';
import { useQuery, useResult, useErrors } from '@/app/composable';
import { Card, SvgImage, ConfirmModal, ButtonGroup, Pagination } from '@/app/components';
import GET_ASSETS from '../graphql/assets.graphql';
import { AssetOverview } from '../components';
import ModelAssetOverview from '../components/ModelAssetOverview.vue';
import { AssetsAPI, FileAPI } from '../api';
import { AssetTypes } from '../constants';
import store from '@/app/store';
import { UserRoles } from '@/app/constants';

export default defineComponent({
    name: 'Assets',
    components: {
        Card,
        OrbitSpinner,
        AssetOverview,
        SvgImage,
        ConfirmModal,
        ButtonGroup,
        ModelAssetOverview,
        Pagination,
    },
    setup(props, { root }) {
        const assetsElement = ref<HTMLElement>();
        const isAdmin = computed(() =>  !!store.getters.auth.hasRole(UserRoles.Admin)).value;

        let assetsFilters: any= ref(  [
            { id: 1, label: 'data assets' },
            { id: 2, label:'results'},
            // { id: 3, label: 'models' }
        ]);
       
        if(isAdmin){
            assetsFilters=ref(  [
            { id: 1, label: 'data assets' },
            { id: 2, label:'results'},
            { id: 3, label: 'models' }
        ]);
        }
        
        const pageSize = 10;
        const page = ref<number>(1);

        const setPage = (newPage: number) => {
            page.value = newPage;
            if (assetsElement.value) {
                assetsElement.value.scrollIntoView({ behavior: 'smooth' });
            }
        };
        const currentPage = computed({
            get: () => page.value,
            set: (newPage: number) => {
                setPage(newPage);
            },
        });
        const currentFilter = ref({ id: 1, label: 'datasets' });
        const showDeleteModal = ref(false);
        const toBeDeleted = ref<number | null>(null);
        const draftModelToBeDeleted = ref<string | null>(null);
        const modelAssetToBeDeleted = ref<boolean>(false);
        const assetFiles = ref<any>([]);

        const { checkGQLAuthentication } = useErrors(root.$route);

        const { loading, error, result, onError, refetch } = useQuery(GET_ASSETS, {}, { fetchPolicy: 'no-cache' });

        onError(checkGQLAuthentication);

        const assets = useResult(result, null, (data: any) => data.myAssets);

        const modelAssets: any = computed(() => {
            let models = [];
            if (assets.value && assets.value.length) {
                if (assets.value && assets.value.length) {
                    models = assets.value.filter((a: any) => a.assetType.id === '3'); // assetTypes.value?.['model'].toString()
                }
                if (assetFiles.value && assetFiles.value.length) {
                    models = models.concat(assetFiles.value);
                }
                return models;
            }
            return [];
        });

        const filteredAssets = computed(() => {
            if (currentFilter.value.label === AssetTypes.Models) {
                return modelAssets.value;
            }
            return assets.value
                ? assets.value.filter((a: any) => a.assetType.id === currentFilter.value.id.toString())
                : [];
        });

        const visibleAssets = computed(() => {
            if (filteredAssets.value) {
                const sortedAssets = R.sortWith([R.descend(R.prop('updatedAt'))], filteredAssets.value as any[]);
                return sortedAssets.slice((page.value - 1) * pageSize, (page.value - 1) * pageSize + pageSize);
            }
            return [];
        });

        const edit = (id: number) => {
            root.$router.push({ name: 'assets:edit', params: { id: `${id}` } });
        };

        const view = (id: number) => {
            root.$router.push({ name: 'assets:view', params: { id: `${id}` } });
        };

        const editModelAsset = (modelAsset: any) => {
            if (modelAsset.status === 'draft') {
                root.$router.push({
                    name: 'assets:model:publish',
                    params: { id: modelAsset.id, status: modelAsset.status },
                });
            } else {
                root.$router.push({ name: 'assets:model:edit', params: { id: modelAsset.id } });
            }
        };

        const { exec } = useAxios(true);

        const retrieveAssetFiles = () => {
            exec(FileAPI.getModelFiles()).then((res: any) => {
                assetFiles.value = res.data;
            });
        };

        const destroy = async () => {
            if (toBeDeleted.value) {
                exec(AssetsAPI.deleteAsset(toBeDeleted.value))
                    .then((res: any) => {
                        const asset = assets.value.find((item: any) => item.id === toBeDeleted.value);
                        root.$set(asset, 'status', res.data.status);
                        root.$set(asset, 'updatedAt', res.data.updatedAt);
                        toBeDeleted.value = null;

                        const idx = R.findIndex(R.propEq('filename', asset.name), assetFiles.value as any[]);
                        if (~idx) {
                            assetFiles.value.splice(idx);
                        }
                        (root as any).$toastr.s('Asset deleted successfuly', 'Success');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Deleting Asset failed', 'Error');
                    });
            }
        };

        const confirmDelete = (id: any, assetName?: string) => {
            modelAssetToBeDeleted.value = false;

            if (assetName) {
                // model asset to be deleted
                if (id) {
                    draftModelToBeDeleted.value = id; // filename id
                } else {
                    const asset = assets.value.find((ass: any) => ass.name === assetName);
                    toBeDeleted.value = asset.id;
                }
                modelAssetToBeDeleted.value = true;
            } else {
                toBeDeleted.value = id;
            }

            showDeleteModal.value = true;
        };

        const filterAssets = (label: string) => {
            setPage(1);
            const selection: any = assetsFilters.value.find((f: any) => f.label === label);
            currentFilter.value = selection;
        };

        const deleteDraftModelAsset = async () => {
            if (draftModelToBeDeleted.value) {
                await exec(AssetsAPI.deleteDraftModelAsset(draftModelToBeDeleted.value))
                    .then(() => {
                        retrieveAssetFiles();
                        refetch();
                        (root as any).$toastr.s('Draft Model Asset deleted successfuly', 'Success');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Deleting Asset failed', 'Error');
                    });

                draftModelToBeDeleted.value = null;
            }
        };

        const assetsExist = computed(
            () => (assets.value && assets.value.length > 0) || (assetFiles.value && assetFiles.value.length > 0),
        );

        const archiveAsset = (id: number) => {
            exec(AssetsAPI.archiveAsset(id))
                .then(() => {
                    refetch();
                    (root as any).$toastr.s('Model Asset archived successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Archiving Model Asset failed', 'Error');
                });
        };

        const restoreAsset = (id: number) => {
            exec(AssetsAPI.restoreArchivedAsset(id))
                .then(() => {
                    refetch();
                    (root as any).$toastr.s('Model Asset restored successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Restoring Model Asset failed', 'Error');
                });
        };

        const modalConfirmed = () => {
            showDeleteModal.value = false;
            if (draftModelToBeDeleted.value) {
                deleteDraftModelAsset();
            } else {
                destroy();
            }
        };

        const modalMessage = computed(() => {
            return modelAssetToBeDeleted.value
                ? 'By deleting this model, all its related data will be removed. Any finalized workflows that include this model in their configuration will be deprecated. This action cannot be reverted.'
                : 'Are you sure you want to delete this asset? All the related data will be permanently removed. This action cannot be undone';
        });

        const cancelAction = () => {
            showDeleteModal.value = false;
            toBeDeleted.value = null;
            draftModelToBeDeleted.value = null;
        };

        retrieveAssetFiles();

        return {
            loading,
            error,
            assets,
            edit,
            currentPage,
            visibleAssets,
            dayjs,
            view,
            destroy,
            confirmDelete,
            showDeleteModal,
            currentFilter,
            assetsFilters,
            filteredAssets,
            filterAssets,
            deleteDraftModelAsset,
            AssetTypes,
            editModelAsset,
            assetsExist,
            modelAssets,
            archiveAsset,
            restoreAsset,
            modelAssetToBeDeleted,
            draftModelToBeDeleted,
            modalConfirmed,
            modalMessage,
            cancelAction,
            pageSize,
            assetsElement,
        };
    },
});
