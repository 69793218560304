


























































































































































































































































































































































































































































































































































































import { defineComponent, ref, computed, watch, inject, Ref } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { FormBlock, Scrollbar } from '@/app/components';
import store from '@/app/store';
import { GeneralPolicy, ExceptionPolicy } from '@/modules/access-policy/models';
import { AssetsAPI } from '../api';
import { AssetType, StatusCode } from '../constants';
import { useAssetMetadata } from '../composable/asset-metadata';
import { AccessPolicy } from '../../access-policy/components';
import { useAssetStatus } from '../composable/asset-status';
import {renamings} from '@/app/utilities';

export default defineComponent({
    name: 'EditAsset',
    props: {
        id: {
            type: [Number, String],
            required: false,
        },
    },
    components: {
        OrbitSpinner,
        FormBlock,
        AccessPolicy,
        Scrollbar,
    },
    setup(props, { root }) {
        const assetDisplayName = ref<string>('');
        const isFeatureEnabled: any = inject('isEnabled');
        const isFresco: boolean = true;
        const id: number | null = props.id ? parseInt(`${props.id}`, 10) : null;
        const domain = ref<any>(null);
        const {
            asset,
            accessLevel,
            copyrightOwner,
            domainsIdToUID,
            domainsOptions,
            referenceAssets,
            spatialIDfieldName,
            customLicense,
            temporalFields,
            spatialFields,
            getGeneralSchema,
            getDistributionSchema,
            getTemporalResolutionSchema,
            getSpatialResolutionSchema,
            getLicensingSchema,
            getPricingSchema,
            initAsset,
            mergeAsset,
            accessLevelOptions,
            resultAccessLevelOptions,
            temporalCoverageOptions,
            spatialCoverageOptions,
            checkLicense,
            locationOptions,
            assetType,
        } = useAssetMetadata(id);

        const isResult = computed(() => assetType.value === AssetType.Result);
        const { exec, loading, error } = useAxios();
        const validForms = ref<any>({
            general: false,
            generalMetadata: false,
            distribution: false,
            temporalCoverage: false,
            spatialCoverage: false,
            temporalResolution: false,
            spatialResolution: false,
            accessLevelCopyrightOwner: false,
            licensing: true,
            pricing: false,
        });
        const user = computed(() => store.state.auth.user);
        const showLicensing = computed(
            () => isFeatureEnabled('asset.metadata.license') || isFeatureEnabled('access-policies'),
        );
        const status: Ref<StatusCode | undefined> = computed((): StatusCode | undefined => asset.value?.status);

        const { label: assetStatusLabel, colour: assetStatusClass } = useAssetStatus(status);

        const metadata = { general: true, distribution: true, extent: true, licensing: true, pricing: true };
        if(isFresco) {
            metadata.licensing = true;
            metadata.pricing = false;            
        }
        const emptyAsset = initAsset(metadata);

        const accessPolicies = ref({ generalPolicy: GeneralPolicy.DENY_ALL, policies: [] });

        const saveClicked = ref<boolean>(false);

        const contentRef = ref<any>(null);

        const startDisabledDates = computed(() => {
            if (
                asset.value &&
                asset.value.metadata &&
                asset.value.metadata.extent &&
                asset.value.metadata.extent.temporalCoverage &&
                asset.value.metadata.extent.temporalCoverage.timePeriod &&
                asset.value.metadata.extent.temporalCoverage.timePeriod[0].max
            ) {
                return {
                    from: new Date(asset.value.metadata.extent.temporalCoverage.timePeriod[0].max),
                };
            }
            return { from: null };
        });

        const endDisabledDates = computed(() => {
            if (
                asset.value &&
                asset.value.metadata &&
                asset.value.metadata.extent &&
                asset.value.metadata.extent.temporalCoverage &&
                asset.value.metadata.extent.temporalCoverage.timePeriod &&
                asset.value.metadata.extent.temporalCoverage.timePeriod[0].min
            ) {
                return {
                    to: new Date(asset.value.metadata.extent.temporalCoverage.timePeriod[0].min),
                };
            }
            return { to: null };
        });

        // Load asset (if an id is provided)
        if (id) {
            exec(AssetsAPI.getAsset(id)).then((res: any) => {
                mergeAsset(emptyAsset, res.data);
                assetDisplayName.value = R.clone(asset.value.name);
                if (
                    !(
                        user.value.id === asset.value.createdBy.id ||
                        (user.value.organisationId !== null &&
                            user.value.organisationId === asset.value.createdBy.organisationId)
                    )
                ) {
                    root.$router.push({ name: 'assets' });
                    (root as any).$toastr.e('You do not have access to edit this asset', 'Error');
                }
            });
        }

        // Methods
        const cancel = () => {
            root.$router.push({ name: 'assets' });
        };

        const getAccessPoliciesJSON = () => {
            const json = [];
            accessPolicies.value.policies.forEach((policy: ExceptionPolicy) => {
                json.push(policy.toJSON());
            });
            json.push(accessPolicies.value.generalPolicy.toJSON());
            return json;
        };

        const saveChanges = async () => {
            root.$delete(asset.value, 'createdAt');
            root.$delete(asset.value, 'updatedAt');
            if (
                (!asset.value.structure.domain || !asset.value.structure.domain.uid) &&
                domain.value &&
                domainsIdToUID.value
            ) {
                const keys = Object.keys(domainsOptions.value);
                const keysUid = Object.keys(domainsIdToUID.value);
                if (keys.includes(domain.value) && keysUid.includes(domain.value)) {
                    const name = domainsOptions.value[domain.value];
                    const uid = domainsIdToUID.value[domain.value];
                    const domainObject = {
                        ...uid,
                        name,
                    };
                    if (asset.value.structure) {
                        asset.value.structure.domain = domainObject;
                    }
                }
            }
            // general metadata
            if (isFeatureEnabled('asset.metadata.general') && metadata.general && asset.value.metadata.general) {
                if (asset.value.metadata.general.reference) {
                    const keys = Object.keys(referenceAssets.value);
                    if (keys.includes(asset.value.metadata.general.reference)) {
                        const name = referenceAssets.value[asset.value.metadata.general.reference];
                        const reference = {
                            id: Number(asset.value.metadata.general.reference),
                            name,
                        };
                        asset.value.metadata.general.reference = reference;
                    }
                }
            }
            // extent metadata
            if (isFeatureEnabled('asset.metadata.extend') && metadata.extent && asset.value.metadata.extent) {
                switch (asset.value.metadata.extent.temporalCoverage.unit) {
                    case 'Not applicable':
                        asset.value.metadata.extent.temporalCoverage = {
                            type: null,
                            field: null,
                            value: null,
                            min: null,
                            max: null,
                            unit: asset.value.metadata.extent.temporalCoverage.unit,
                        };
                        break;
                    case 'Time Period':
                        asset.value.metadata.extent.temporalCoverage = {
                            type: 'custom',
                            field: null,
                            value: null,
                            min: asset.value.metadata.extent.temporalCoverage.timePeriod[0].min,
                            max: asset.value.metadata.extent.temporalCoverage.timePeriod[0].max,
                            unit: asset.value.metadata.extent.temporalCoverage.unit,
                        };
                        break;
                    case 'Single Date':
                        asset.value.metadata.extent.temporalCoverage = {
                            type: 'custom',
                            field: null,
                            value: null,
                            min: asset.value.metadata.extent.temporalCoverage.min,
                            max: asset.value.metadata.extent.temporalCoverage.min,
                            unit: asset.value.metadata.extent.temporalCoverage.unit,
                        };
                        break;
                    case 'Calculated based on data':
                        if (temporalFields.value) {
                            const keys = Object.keys(temporalFields.value);
                            if (keys.includes(asset.value.metadata.extent.temporalCoverage.field)) {
                                const name = temporalFields.value[
                                    asset.value.metadata.extent.temporalCoverage.field
                                ].replaceAll(' > ', '__');
                                const field = {
                                    uid: asset.value.metadata.extent.temporalCoverage.field,
                                    name,
                                };
                                asset.value.metadata.extent.temporalCoverage.field = field;
                            }
                        }
                        asset.value.metadata.extent.temporalCoverage = {
                            type: 'field',
                            field: asset.value.metadata.extent.temporalCoverage.field,
                            value: null,
                            min: null,
                            max: null,
                            unit: asset.value.metadata.extent.temporalCoverage.unit,
                        };
                        break;
                    default:
                        asset.value.metadata.extent.temporalCoverage = {
                            type: 'custom',
                            field: null,
                            value: Number(asset.value.metadata.extent.temporalCoverage.value),
                            min: null,
                            max: null,
                            unit: asset.value.metadata.extent.temporalCoverage.unit,
                        };
                }
                switch (asset.value.metadata.extent.spatialCoverage.unit) {
                    case 'Not applicable':
                        asset.value.metadata.extent.spatialCoverage = {
                            type: null,
                            field: null,
                            value: null,
                            values: [],
                            coordinates: null,
                            unit: asset.value.metadata.extent.spatialCoverage.unit,
                        };
                        break;
                    case 'Specific Continent/Countries':
                        asset.value.metadata.extent.spatialCoverage = {
                            type: 'custom',
                            field: null,
                            value: null,
                            values: asset.value.metadata.extent.spatialCoverage.values,
                            coordinates: null,
                            unit: asset.value.metadata.extent.spatialCoverage.unit,
                        };
                        break;
                    case 'Exact Location':
                        asset.value.metadata.extent.spatialCoverage = {
                            type: 'custom',
                            field: null,
                            value: null,
                            values: [],
                            coordinates: {
                                lat: Number(asset.value.metadata.extent.spatialCoverage.exactLocation[0].lat),
                                lon: Number(asset.value.metadata.extent.spatialCoverage.exactLocation[0].lon),
                            },
                            unit: asset.value.metadata.extent.spatialCoverage.unit,
                        };
                        break;
                    case 'Calculated based on data':
                        if (spatialFields.value) {
                            const keys = Object.keys(spatialFields.value);
                            if (keys.includes(asset.value.metadata.extent.spatialCoverage.field)) {
                                const name = spatialFields.value[
                                    asset.value.metadata.extent.spatialCoverage.field
                                ].replaceAll(' > ', '__');
                                const field = {
                                    uid: asset.value.metadata.extent.spatialCoverage.field,
                                    name,
                                };
                                asset.value.metadata.extent.spatialCoverage.field = field;
                            }
                        }
                        asset.value.metadata.extent.spatialCoverage = {
                            type: 'field',
                            field: asset.value.metadata.extent.spatialCoverage.field,
                            value: null,
                            values: [],
                            coordinates: null,
                            unit: asset.value.metadata.extent.spatialCoverage.unit,
                        };
                        break;
                    default:
                        asset.value.metadata.extent.spatialCoverage = {
                            type: 'custom',
                            field: spatialIDfieldName,
                            value: asset.value.metadata.extent.spatialCoverage.value,
                            values: [],
                            coordinates: null,
                            unit: asset.value.metadata.extent.spatialCoverage.unit,
                        };
                }
                if (asset.value.metadata.extent.temporalResolution.value) {
                    asset.value.metadata.extent.temporalResolution.value = Number(
                        asset.value.metadata.extent.temporalResolution.value,
                    );
                }
            }
            // licensing metadata
            if (showLicensing.value && metadata.licensing && asset.value.metadata.license) {
                if (!asset.value.metadata.license.derivation || asset.value.metadata.license.derivation.length === 0) {
                    asset.value.metadata.license.derivation = ['Prohibited'];
                }
                asset.value.metadata.license.accessLevel = accessLevel.value;
                asset.value.metadata.license.copyrightOwner = copyrightOwner.value;
            }
            // pricing metadata
            if (isFeatureEnabled('asset.pricing.metadata') && metadata.pricing && asset.value.metadata.pricing) {
                if (asset.value.metadata.pricing.costCurrency) {
                    asset.value.metadata.pricing.cost = Number(asset.value.metadata.pricing.costCurrency[0].cost);
                    asset.value.metadata.pricing.currency = asset.value.metadata.pricing.costCurrency[0].currency;
                }

                if (asset.value.metadata.pricing.calculationScheme === 'Request Dependent') {
                    asset.value.metadata.pricing.cost = null;
                }
            }

            asset.value.policies = getAccessPoliciesJSON();

            if (id) {
                const payload = R.clone(asset.value);
                if (payload.metadata && payload.metadata.pricing && payload.metadata.pricing.costCurrency) {
                    delete payload.metadata.pricing.costCurrency;
                }
                await exec(AssetsAPI.updateAsset(id, payload as any));
                root.$router.push({ name: 'assets:view', params: { id: `${id}` } });
            }
        };

        const save = computed(() => {
            if (
                !validForms.value.general ||
                (isFeatureEnabled('asset.metadata.general') && metadata.general && !validForms.value.generalMetadata) ||
                (isFeatureEnabled('asset.metadata.distribution') &&
                    metadata.distribution &&
                    !validForms.value.distribution) ||
                (isFeatureEnabled('asset.metadata.extend') &&
                    metadata.extent &&
                    (!validForms.value.temporalCoverage ||
                        !validForms.value.spatialCoverage ||
                        !validForms.value.temporalResolution ||
                        !validForms.value.spatialResolution)) ||
                (showLicensing.value &&
                    metadata.licensing &&
                    (!validForms.value.accessLevelCopyrightOwner || !validForms.value.licensing)) ||
                (isFeatureEnabled('asset.pricing.metadata') &&
                    metadata.pricing &&
                    customLicense.value &&
                    accessLevel.value !== 'Confidential' &&
                    accessLevel.value !== 'Public' &&
                    !validForms.value.pricing)
            ) {
                return false;
            }
            return true;
        });

        const submitForms = () => {
            validForms.value = {
                general: false,
                generalMetadata: false,
                distribution: false,
                temporalCoverage: false,
                spatialCoverage: false,
                temporalResolution: false,
                spatialResolution: false,
                accessLevelCopyrightOwner: false,
                licensing: true,
                pricing: false,
            };
            (root as any).$formulate.submit('general');
            if (isFeatureEnabled('asset.metadata.general') && metadata.general) {
                (root as any).$formulate.submit('generalMetadata');
            }
            if (isFeatureEnabled('asset.metadata.distribution') && metadata.distribution) {
                (root as any).$formulate.submit('distribution');
            }
            if (isFeatureEnabled('asset.metadata.extend') && metadata.extent) {
                (root as any).$formulate.submit('temporalCoverage');
                (root as any).$formulate.submit('spatialCoverage');
                (root as any).$formulate.submit('temporalResolution');
                (root as any).$formulate.submit('spatialResolution');
            }
            if (showLicensing.value && metadata.licensing) {
                validForms.value.accessLevelCopyrightOwner = false;
                (root as any).$formulate.submit('accessLevelCopyrightOwner');
                if (!accessLevel.value || accessLevel.value === 'Confidential') {
                    validForms.value.licensing = true;
                } else {
                    validForms.value.licensing = false;
                    (root as any).$formulate.submit('licensing');
                }
            }
            if (
                isFeatureEnabled('asset.pricing.metadata') &&
                metadata.pricing &&
                customLicense.value &&
                accessLevel.value &&
                accessLevel.value !== 'Confidential' &&
                accessLevel.value !== 'Public'
            ) {
                (root as any).$formulate.submit('pricing');
            }
        };

        const formSubmitted = (name: string) => {
            saveClicked.value = true;
            validForms.value[name] = true;
            if (save.value) {
                saveChanges();
            } else {
                contentRef.value.scrollTo({ top: 0, behavior: 'smooth' });
            }
        };

        const addSpatialCoverage = (value: any) => {
            asset.value.metadata.extent.spatialCoverage.values = value;
        };

        const resetLicenseAndPricing = (level: any) => {
            if (level) {
                if (level === 'Confidential') {
                    asset.value.metadata.license.license = null;
                    asset.value.metadata.license.copyrightOwner = null;
                    asset.value.metadata.license.link = null;
                    asset.value.metadata.license.derivation = null;
                    asset.value.metadata.license.attribution = null;
                    asset.value.metadata.license.reproduction = null;
                    asset.value.metadata.license.distribution = null;
                    asset.value.metadata.license.shareAlike = null;
                    asset.value.metadata.license.reContext = null;
                    asset.value.metadata.license.offlineRetention = null;
                    asset.value.metadata.license.targetPurpose = null;
                    if (asset.value.metadata.pricing) {
                        asset.value.metadata.pricing.cost = null;
                        asset.value.metadata.pricing.currency = null;
                        asset.value.metadata.pricing.paymentMethod = null;
                        asset.value.metadata.pricing.calculationScheme = null;
                    }
                } else if (level === 'Public' && asset.value.metadata.pricing) {
                    asset.value.metadata.pricing.cost = null;
                    asset.value.metadata.pricing.currency = null;
                    asset.value.metadata.pricing.paymentMethod = null;
                    asset.value.metadata.pricing.calculationScheme = null;
                } else if (level === 'Private' && asset.value.metadata.license.license !== 'Custom') {
                    asset.value.metadata.license.license = 'Custom';
                    checkLicense({ id: 'Custom', label: 'Custom' });
                }
            }
        };

        watch(
            () => asset.value && asset.value.metadata.license && accessLevel.value,
            (level) => resetLicenseAndPricing(level),
        );

        return {
            contentRef,
            domain,
            accessLevel,
            copyrightOwner,
            domainsOptions,
            customLicense,
            cancel,
            error,
            asset,
            loading,
            metadata,
            getGeneralSchema,
            getDistributionSchema,
            getTemporalResolutionSchema,
            getSpatialResolutionSchema,
            getLicensingSchema,
            getPricingSchema,
            accessLevelOptions,
            resultAccessLevelOptions,
            temporalCoverageOptions,
            spatialCoverageOptions,
            submitForms,
            formSubmitted,
            accessPolicies,
            user,
            validForms,
            save,
            saveClicked,
            temporalFields,
            spatialFields,
            locationOptions,
            addSpatialCoverage,
            isFeatureEnabled,
            showLicensing,
            startDisabledDates,
            endDisabledDates,
            assetStatusLabel,
            assetStatusClass,
            assetDisplayName,
            isResult,
            renamings,
            isFresco
        };
    },
});
