var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.generalPolicy)?_c('div',{staticClass:"flex flex-col space-y-6"},[(_vm.error)?_c('div',{staticClass:"flex flex-row space-x-2 text-red-600",attrs:{"title":_vm.error}},[_c('svg',{staticClass:"w-6 h-6",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"}})]),_c('div',[_vm._v("Unable to process access policies")])]):[_c('div',{staticClass:"flex flex-row"},[(_vm.generalPolicy.allow)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.readonly || _vm.generalPolicy.allow),expression:"!readonly || generalPolicy.allow"}],staticClass:"w-1/2 p-3 rounded-l",class:{
                        'bg-green-700 text-white': !_vm.readonly && _vm.generalPolicy.allow,
                        'bg-neutral-200 text-neutral-400 hover:bg-green-700 hover:text-white':
                            !_vm.generalPolicy.allow && !_vm.pendingChangePolicy,
                        'bg-orange-400 text-white': !_vm.generalPolicy.allow && _vm.pendingChangePolicy,
                        'cursor-pointer': !_vm.generalPolicy.allow && _vm.policyInEditMode === null && !_vm.showAddPolicy,
                        'cursor-not-allowed hover:text-neutral-400 hover:bg-neutral-200':
                            !_vm.generalPolicy.allow && (_vm.policyInEditMode !== null || _vm.showAddPolicy),
                        'rounded-r  text-green-700': _vm.readonly && _vm.generalPolicy.allow,
                        'w-full': _vm.readonly,
                    }},[_c('div',{staticClass:"flex flex-row space-x-8"},[_c('div',{staticClass:"flex flex-row space-x-2"},[_c('svg',{staticClass:"w-6 h-6 my-auto",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"}})]),_c('div',[_vm._v(_vm._s(_vm.GeneralPolicy.ALLOW_ALL.text))])])])]):_vm._e(),(!_vm.generalPolicy.allow)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.readonly || !_vm.generalPolicy.allow),expression:"!readonly || !generalPolicy.allow"}],staticClass:"w-1/2 p-3 rounded-r",class:{
                        'bg-red-700 text-white': !_vm.readonly && !_vm.generalPolicy.allow,
                        'bg-neutral-200 text-neutral-400 hover:bg-red-700 hover:text-white ':
                            _vm.generalPolicy.allow && !_vm.pendingChangePolicy,
                        'bg-orange-400 text-white': _vm.generalPolicy.allow && _vm.pendingChangePolicy,
                        'cursor-pointer': _vm.generalPolicy.allow && _vm.policyInEditMode === null && !_vm.showAddPolicy,
                        'cursor-not-allowed hover:text-neutral-400 hover:bg-neutral-200':
                            _vm.generalPolicy.allow && (_vm.policyInEditMode !== null || _vm.showAddPolicy),
                        'rounded-l  text-red-700': _vm.readonly && !_vm.generalPolicy.allow,
                        'w-full': _vm.readonly,
                    },on:{"click":function($event){_vm.generalPolicy.allow && _vm.policyInEditMode === null && !_vm.showAddPolicy
                            ? _vm.changeGeneralPolicy(_vm.GeneralPolicy.DENY_ALL)
                            : null}}},[(_vm.readonly)?_c('div',{staticClass:"flex flex-row space-x-2"},[_c('svg',{staticClass:"w-6 h-6 my-auto",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"}})]),_c('div',[(_vm.organisationId)?_c('span',[_vm._v(_vm._s(_vm.GeneralPolicy.DENY_OTHER_ORGS.text))]):_c('span',[_vm._v(_vm._s(_vm.GeneralPolicy.DENY_ALL.text))])])]):(_vm.generalPolicy.allow && _vm.pendingChangePolicy)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Doing so will invert any exception you have defined!"),expression:"`Doing so will invert any exception you have defined!`"}]},[_c('div',{staticClass:"flex flex-row space-x-2"},[_c('svg',{staticClass:"w-6 h-6 my-auto",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}})]),_vm._m(0)])]):_c('div',{staticClass:"flex flex-row space-x-8"},[_c('div',{staticClass:"flex flex-row space-x-2"},[_c('svg',{staticClass:"w-6 h-6 my-auto",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"}})]),_c('div',[(_vm.organisationId)?_c('span',[_vm._v(_vm._s(_vm.GeneralPolicy.DENY_OTHER_ORGS.text))]):_c('span',[_vm._v(_vm._s(_vm.GeneralPolicy.DENY_ALL.text))])])])])]):_vm._e()]),(_vm.processedPolicies.length > 0)?_c('div',{staticClass:"flex flex-col space-y-2",class:{ ' ml-4': _vm.readonly }},[_c('div',{staticClass:"flex flex-row my-auto space-x-2",class:{
                        'text-green-700': !_vm.generalPolicy.allow,
                        'text-red-700': _vm.generalPolicy.allow,
                    }},[_c('div',[_vm._v("Exceptions")]),_c('div',{staticClass:"my-auto text-xs text-neutral-600"},[_vm._v("(Evaluated sequentially)")])]),_c('draggable',{attrs:{"list":_vm.processedPolicies,"handle":".handle"},on:{"sort":_vm.policyMoved}},_vm._l((_vm.processedPolicies),function(policy,idx){return _c('access-policy-node',{key:idx,attrs:{"readonly":_vm.readonly || _vm.policyInEditMode !== null,"editMode":_vm.policyInEditMode === idx},on:{"update-edit-mode":function($event){_vm.policyInEditMode = null},"edit":function($event){return _vm.editPolicyException(idx)},"cancel":function($event){_vm.policyInEditMode = null},"save":function($event){_vm.policyInEditMode = null},"destroy":function($event){return _vm.deletePolicyException(idx)}},model:{value:(_vm.processedPolicies[idx]),callback:function ($$v) {_vm.$set(_vm.processedPolicies, idx, $$v)},expression:"processedPolicies[idx]"}})}),1)],1):_vm._e(),(!_vm.readonly && !_vm.policyInEditMode)?_c('div',[_c('div',{staticClass:"flex flex-col"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.policyInEditMode === null),expression:"policyInEditMode === null"}],staticClass:"flex flex-row p-2 space-x-2 rounded cursor-pointer",class:{
                            'text-green-700 hover:bg-green-100 hover:text-green-900': !_vm.generalPolicy.allow,
                            'text-red-700 hover:bg-red-100 hover:text-red-900': _vm.generalPolicy.allow,
                        },on:{"click":function($event){_vm.showAddPolicy = !_vm.showAddPolicy}}},[_c('svg',{staticClass:"w-4 h-4 my-auto",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 6v6m0 0v6m0-6h6m-6 0H6"}})]),_c('div',[(_vm.processedPolicies.length > 0)?[_vm._v("Add another exception")]:[_vm._v("Add an exception")]],2)]),(_vm.showAddPolicy)?_c('access-policy-edit',{attrs:{"defaultPolicyEffect":_vm.defaultPolicyEffect,"readonly":_vm.readonly},on:{"save":_vm.addPolicy,"cancel":function($event){_vm.showAddPolicy = false}}}):_vm._e()],1)]):_vm._e()]],2):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',[_vm._v("Click again to confirm")])])}]

export { render, staticRenderFns }