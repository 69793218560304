var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col p-4 my-2 space-y-12 text-sm rounded bg-neutral-100"},[_c('div',{staticClass:"flex flex-col space-y-4"},[_vm._l((_vm.newPolicy.otherConditions),function(condition,idx){return _c('div',{key:idx,staticClass:"flex flex-col space-y-2"},[_c('access-policy-edit-node',{attrs:{"identifier":idx,"field":condition.field,"operant":condition.operant,"values":condition.fieldValues,"isValid":condition.isValid()},on:{"updated-field":_vm.updateField,"updated-operant":_vm.updateOperant,"updated-value":_vm.updateValue,"delete":function($event){return _vm.deleteNewPolicyCondition(idx)}}}),_vm._m(0,true)],1)}),_c('div',{staticClass:"flex flex-row justify-between space-x-2"},[_c('div',{staticClass:"flex flex-row flex-1 ml-1 space-x-6"},[_c('access-policy-field-select',{staticClass:"w-full my-auto",attrs:{"fields":_vm.fields},model:{value:(_vm.newPolicy.currentField),callback:function ($$v) {_vm.$set(_vm.newPolicy, "currentField", $$v)},expression:"newPolicy.currentField"}}),(_vm.newPolicy.currentField)?_c('access-policy-operant-select',{staticClass:"w-full my-auto",attrs:{"field":_vm.newPolicy.currentField},model:{value:(_vm.newPolicy.currentOperator),callback:function ($$v) {_vm.$set(_vm.newPolicy, "currentOperator", $$v)},expression:"newPolicy.currentOperator"}}):_c('div',{staticClass:"w-full"}),(_vm.newPolicy.currentField)?_c('access-policy-value-select',{staticClass:"w-full my-auto",attrs:{"field":_vm.newPolicy.currentField},model:{value:(_vm.newPolicy.currentValue),callback:function ($$v) {_vm.$set(_vm.newPolicy, "currentValue", $$v)},expression:"newPolicy.currentValue"}}):_c('div',{staticClass:"w-full"})],1),(
                    _vm.newPolicy.currentField !== null ||
                    _vm.newPolicy.currentOperator !== null ||
                    _vm.newPolicy.currentOperator != null
                )?_c('div',{staticClass:"my-auto cursor-pointer text-neutral-500 hover:text-red-700",attrs:{"title":"Remove condition"},on:{"click":_vm.clearAddConditionForm}},[_c('svg',{staticClass:"w-4 h-4 my-auto",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"}})])]):_c('div',{staticClass:"w-4"})]),_c('button',{staticClass:"w-full m-2 tracking-wide border-2 rounded cursor-pointer border-primary-700 text-primary-700 hover:bg-primary-100 disabled:cursor-not-allowed disabled:opacity-50",attrs:{"disabled":!_vm.canAddCondition,"title":_vm.canAddCondition
                    ? 'Add another condition'
                    : 'You need to complete the previous condition before adding another'},on:{"click":_vm.addCondition}},[_c('div',{staticClass:"flex flex-row justify-center p-1 space-x-2"},[_c('svg',{staticClass:"w-4 h-4 my-auto",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 6v6m0 0v6m0-6h6m-6 0H6"}})]),_vm._m(1)])])],2),_c('div',{staticClass:"flex flex-col space-y-6"},[(_vm.policyErrors.length > 0)?_c('div',{staticClass:"flex flex-col px-2 py-1 space-y-2 text-orange-700 bg-orange-100"},[_c('div',{staticClass:"font-bold"},[_vm._v("Consistency check errors")]),_vm._l((_vm.policyErrors),function(error,idx){return _c('div',{key:idx,staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-row space-x-2"},[_c('div',{staticClass:"mt-1"},[_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}})])]),_c('div',[_vm._v(_vm._s(error))])])])})],2):_vm._e(),_c('div',{staticClass:"px-2 py-1 rounded",class:{
                'bg-green-100 text-green-900': _vm.policyEffect,
                'bg-red-100 text-red-900': !_vm.policyEffect,
            },attrs:{"title":"Policy exception preview"}},[(_vm.policyPreview.conditions.length > 0)?_c('div',{staticClass:"flex flex-row space-x-2"},[(_vm.policyPreview.allow)?_c('div',{staticClass:"my-auto text-green-700"},[_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"}})])]):_c('div',{staticClass:"my-auto text-red-700"},[_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"}})])]),_c('access-policy-preview',{attrs:{"policy":_vm.policyPreview}})],1):_c('div',{staticClass:"italic"},[_vm._v("You need to define at least one condition for policy to be added")])]),_c('div',{staticClass:"flex flex-row ml-auto space-x-6"},[_c('button',{staticClass:"px-3 py-1 rounded opacity-50 cursor-pointer bg-neutral-300 hover:opacity-100 text-neutral-800",on:{"click":function($event){_vm.clearAddPolicyForm(), _vm.emit('cancel')}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"px-6 py-1 text-white rounded hover:opacity-100 disabled:opacity-50 disabled:cursor-not-allowed",class:{
                    'bg-green-700': _vm.policyEffect,
                    'bg-red-700': !_vm.policyEffect,
                },attrs:{"disabled":_vm.canAddPolicyErrorMessage,"title":_vm.canAddPolicyErrorMessage},on:{"click":_vm.savePolicy}},[(_vm.isNew)?[_vm._v("Create policy exception")]:_vm._e(),(!_vm.isNew)?[_vm._v("Update policy exception")]:_vm._e()],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row w-full p-1 m-2 space-x-2 tracking-wide uppercase border-t border-b text-primary-700 border-neutral-300"},[_c('span',{staticClass:"mx-auto"},[_vm._v("AND")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v("AND "),_c('span',{staticClass:"opacity-75"},[_vm._v("(add another condition)")])])}]

export { render, staticRenderFns }