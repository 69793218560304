



































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref, computed, inject, watch, Ref } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import { OrbitSpinner } from 'epic-spinners';
import dayjs from 'dayjs';
import { VerticalFormBlock, FormBlock, DataModelTree, Tabs, Scrollbar } from '@/app/components';
import { useFilters } from '@/app/composable/filters';
import store from '@/app/store';
import { ModelAPI } from '@/modules/data-checkin/api';
import { AssetsAPI } from '../api';
import ModelFeature from '../components/ModelFeature.vue';
import { StatusCode, AssetType } from '../constants';
import { useAssetMetadata } from '../composable/asset-metadata';
import { AccessPolicy } from '../../access-policy/components';
import { useAsset } from '../composable/asset';
import { useAssetStatus } from '../composable/asset-status';
import { DatabaseIcon } from '@vue-hero-icons/solid';

export default defineComponent({
    name: 'ViewAsset',
    props: {
        id: {
            type: [Number, String],
            required: false,
        },
    },
    components: {
        VerticalFormBlock,
        FormBlock,
        OrbitSpinner,
        DataModelTree,
        Tabs,
        AccessPolicy,
        Scrollbar,
        DatabaseIcon,
        ModelFeature,
    },
    setup(props) {
        const { assetTypeName } = useAsset();
        const isFeatureEnabled: any = inject('isEnabled');
        const tabs = ref<{ title: string }[]>([]);
        const id: number | null = props.id ? parseInt(`${props.id}`, 10) : null;
        const activeTab = ref(0);
        const { domains, locationOptions, createTreeStructure } = useAssetMetadata(id);
        const metadata = { general: true, distribution: true, extent: true, licensing: true, pricing: true };
        const asset = ref<any>(null);
        const flatModel = ref<any>(null);
        const dataStructure = ref<any>(null);
        const { exec, loading, error } = useAxios();
        const { fromNow } = useFilters();
        const user = computed(() => store.state.auth.user);
        const inputAssets = ref<any>([]);
        const showLicensing = computed(
            () => isFeatureEnabled('asset.metadata.license') || isFeatureEnabled('access-policies'),
        );
        const status: Ref<StatusCode | undefined> = computed((): StatusCode | undefined => asset.value?.status);
        const { label: assetStatusLabel, colour: assetStatusClass } = useAssetStatus(status);

        const assetType = computed(() => {
            if (asset.value && asset.value.assetTypeId) {
                return assetTypeName(asset.value.assetTypeId);
            }
            return AssetType.Dataset;
        });
        const isResult = computed(() => assetType.value === AssetType.Result);

        if (
            isFeatureEnabled('asset.metadata.general') ||
            isFeatureEnabled('asset.metadata.distribution') ||
            isFeatureEnabled('asset.metadata.extend') ||
            isFeatureEnabled('access-policies')
        ) {
            tabs.value.push({ title: 'Overview' });
        }

        const createTabs = () => {
            if (
                (showLicensing.value || isFeatureEnabled('asset.pricing.metadata')) &&
                metadata.licensing &&
                asset.value.metadata.license
            ) {
                tabs.value.push({ title: 'Access Details' });
            }
            if (asset.value.metadata.model && asset.value.metadata.model.featureOrder) {
                tabs.value.push({ title: 'Model Structure' });
            }
            if (asset.value.structure.primaryConcept) {
                tabs.value.push({ title: 'Data Structure' });
            }
        };

        const loadDataStructure = async (domainId: number) => {
            const treeStructure: any[] = [];
            const primaryConceptName = asset.value.structure.primaryConcept.name;
            const { schema } = asset.value.structure;

            let customizedConcepts: any[] = [];

            await exec(ModelAPI.conceptNames(domainId)).then((modelRes: any) => {
                flatModel.value = modelRes.data;
            });
            if (Object.keys(schema).includes('customizedConcepts')) {
                customizedConcepts = schema.customizedConcepts;
            }
            if (Object.keys(schema).includes(primaryConceptName) && flatModel.value) {
                createTreeStructure(
                    primaryConceptName,
                    schema[primaryConceptName],
                    treeStructure,
                    [],
                    flatModel.value,
                    customizedConcepts,
                );
                dataStructure.value = treeStructure;
            }
        };

        const customLicense = computed(() => {
            if (
                asset.value &&
                asset.value.metadata &&
                asset.value.metadata.license &&
                (asset.value.metadata.license.license === undefined ||
                    asset.value.metadata.license.license === null ||
                    asset.value.metadata.license.license === 'Custom')
            ) {
                return true;
            }
            return false;
        });

        const spatialCoverageCountries = computed(() => {
            const array: any = [];
            if (
                asset.value &&
                asset.value.metadata &&
                asset.value.metadata.extent &&
                asset.value.metadata.extent.spatialCoverage &&
                asset.value.metadata.extent.spatialCoverage.values &&
                locationOptions.value
            ) {
                asset.value.metadata.extent.spatialCoverage.values.forEach((value: string) => {
                    locationOptions.value.forEach((global: any) => {
                        if (global.id === value) {
                            array.push(global.label);
                        } else {
                            global.children.forEach((continent: any) => {
                                if (continent.id === value) {
                                    array.push(continent.label);
                                } else {
                                    continent.children.forEach((country: any) => {
                                        if (country.id === value) {
                                            array.push(country.label);
                                        }
                                    });
                                }
                            });
                        }
                    });
                });
            }
            return array;
        });

        const temporalCoverage = computed(() => {
            if (
                asset.value &&
                asset.value.metadata &&
                asset.value.metadata.extent &&
                asset.value.metadata.extent.temporalCoverage &&
                asset.value.metadata.extent.temporalCoverage.unit
            ) {
                switch (asset.value.metadata.extent.temporalCoverage.unit) {
                    case 'Time Period':
                        return `${dayjs(asset.value.metadata.extent.temporalCoverage.min).format(
                            'MMM D, YYYY',
                        )}~${dayjs(asset.value.metadata.extent.temporalCoverage.max).format('MMM D, YYYY')}`;
                    case 'Single Date':
                        return dayjs(asset.value.metadata.extent.temporalCoverage.min).format('MMM D, YYYY');
                    case 'Calculated based on data':
                        if (
                            asset.value.metadata.extent.temporalCoverage.min &&
                            asset.value.metadata.extent.temporalCoverage.max &&
                            asset.value.metadata.extent.temporalCoverage.field
                        ) {
                            const fieldType = asset.value.structure.temporalFields.find(
                                (temporalField: any) =>
                                    temporalField.uid === asset.value.metadata.extent.temporalCoverage.field.uid,
                            ).type;
                            let format = '';
                            let { min, max } = asset.value.metadata.extent.temporalCoverage;
                            switch (fieldType) {
                                case 'date':
                                    format = 'MMM D, YYYY';
                                    break;
                                case 'time':
                                    format = 'HH:mm:ss UTC';
                                    min = `1970-01-01T${min}`;
                                    max = `1970-01-01T${max}`;
                                    break;
                                case 'datetime':
                                    format = 'MMM D, YYYY HH:mm:ss UTC';
                                    break;
                                default:
                                    return 'N/A';
                            }
                            return `${dayjs(min).format(format)} ~ ${dayjs(max).format(format)}`;
                        }
                        return 'N/A';
                    case 'Not applicable':
                        return asset.value.metadata.extent.temporalCoverage.unit;
                    default:
                        return `${asset.value.metadata.extent.temporalCoverage.value} ${asset.value.metadata.extent.temporalCoverage.unit}`;
                }
            }
            return null;
        });

        const spatialCoverage = computed(() => {
            if (
                asset.value &&
                asset.value.metadata &&
                asset.value.metadata.extent &&
                asset.value.metadata.extent.spatialCoverage &&
                asset.value.metadata.extent.spatialCoverage.unit
            ) {
                switch (asset.value.metadata.extent.spatialCoverage.unit) {
                    case 'Specific Continent/Countries':
                        return spatialCoverageCountries.value.join(', ');
                    case 'Exact Location':
                        return `Latitude: ${asset.value.metadata.extent.spatialCoverage.coordinates.lat}, Longitude: ${asset.value.metadata.extent.spatialCoverage.coordinates.lon}`;
                    case 'Calculated based on data':
                        if (
                            asset.value.metadata.extent.spatialCoverage.values &&
                            asset.value.metadata.extent.spatialCoverage.values.length > 0
                        ) {
                            return asset.value.metadata.extent.spatialCoverage.values.join(', ');
                        }
                        return 'N/A';
                    case 'Not applicable':
                        return asset.value.metadata.extent.spatialCoverage.unit;
                    default:
                        return asset.value.metadata.extent.spatialCoverage.value;
                }
            }
            return null;
        });

        const temporalCoverageDescription = computed(() => {
            if (
                asset.value &&
                asset.value.metadata &&
                asset.value.metadata.extent &&
                asset.value.metadata.extent.temporalCoverage &&
                asset.value.metadata.extent.temporalCoverage.unit
            ) {
                switch (asset.value.metadata.extent.temporalCoverage.unit) {
                    case null:
                    case 'Not applicable':
                        return 'The time period during which the data were collected or the time period the data are referring to.';
                    case 'Time Period':
                    case 'Single Date':
                        return 'A named period, date, or date range that the data asset covers.';
                    case 'Years':
                        return 'The number of indicative years to which the data asset refers.';
                    case 'Months':
                        return 'The number of indicative months to which the data asset refers.';
                    case 'Days':
                        return 'The number of indicative days to which the data asset refers.';
                    case 'Hours':
                        return 'The number of indicative hours to which the data asset refers.';
                    case 'Calculated based on data':
                        return 'The period that the data asset covers is extracted from a specific concept/field in the data.';
                    default:
                        return '';
                }
            }
            return '';
        });
        const spatialCoverageDescription = computed(() => {
            if (
                asset.value &&
                asset.value.metadata &&
                asset.value.metadata.extent &&
                asset.value.metadata.extent.spatialCoverage &&
                asset.value.metadata.extent.spatialCoverage.unit
            ) {
                const { unit } = asset.value.metadata.extent.spatialCoverage;
                switch (unit) {
                    case null:
                    case 'Not applicable':
                        return 'The location/area the data refer to or were collected from; either defined directly (e.g. geographical area) or indirectly (i.e. place of interest or an activity that is the subject of the collection).';
                    case 'Specific Continent/Countries':
                        return 'The spatial coverage of the data asset, in terms of countries to which the data asset refers.';
                    case 'Exact Location':
                        return 'The spatial coverage of the data asset, in terms of exact location(s) to which the data asset refers.';
                    case 'Calculated based on data':
                        return 'The spatial coverage of the data asset as extracted from a specific concept/field in the data.';
                    default:
                        return `The spatial coverage of the data asset, in terms of ${unit
                            .substring(8, unit.length)
                            .toLowerCase()}(s) to which the data asset refers.`;
                }
            }
            return '';
        });

        const tabClicked = (idx: number) => {
            activeTab.value = idx;
        };

        const reloadAsset = (domainsValue: any) => {
            if (domainsValue && id) {
                exec(AssetsAPI.getAsset(id)).then(async (res: any) => {
                    // load asset
                    asset.value = res.data;

                    // create tabs in view asset page
                    createTabs();

                    // if domain & primary concept exist then load asset's data structure
                    if (asset.value.structure.domain && asset.value.structure.primaryConcept) {
                        const { majorVersion, uid } = asset.value.structure.domain;
                        // find domain from domains in store and use its id to load data structure
                        const domain = domainsValue.find(
                            (dom: any) => dom.uid === uid && dom.majorVersion === majorVersion,
                        );
                        if (domain) {
                            loadDataStructure(domain.id);
                        }
                    }

                    exec(AssetsAPI.getInputAssets(asset.value.id)).then((resAssets: any) => {
                        inputAssets.value = resAssets.data;
                    });
                });
            }
        };

        const showInputAssetsWarning = computed(() => {
            if (inputAssets.value.length > 0) {
                let attributionRequired = true;
                for (let i = 0; i < inputAssets.value.length; i++) {
                    if (
                        inputAssets.value[i].contractLicenseMetadata &&
                        inputAssets.value[i].contractLicenseMetadata.attribution === 'Not required'
                    ) {
                        attributionRequired = false;
                        break;
                    }
                }
                return !attributionRequired;
            }
            return false;
        });

        watch(
            () => domains.value,
            (domainsValue: any) => {
                reloadAsset(domainsValue);
            },
        );

        reloadAsset(domains.value);

        const isFresco: boolean = true;
        return {
            isFresco,
            error,
            asset,
            loading,
            tabs,
            activeTab,
            dayjs,
            spatialCoverage,
            metadata,
            customLicense,
            dataStructure,
            temporalCoverage,
            temporalCoverageDescription,
            spatialCoverageDescription,
            fromNow,
            tabClicked,
            user,
            isFeatureEnabled,
            showLicensing,
            assetStatusLabel,
            assetStatusClass,
            inputAssets,
            showInputAssetsWarning,
            assetType,
            AssetType,
            isResult,
        };
    },
});
