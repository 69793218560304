import * as R from 'ramda';
import gql from 'graphql-tag';
import { useQuery, useResult } from '@/app/composable';
import { FieldType } from '.';
import { ConditionValue } from '../models';

export class Field {
    static readonly COUNTRY = new Field(
        'org.country',
        'Organisation country',
        FieldType.SELECT,
        'country',
        'countries',
        'from',
        'Aruba',
        `countries {
                id
                name
            }
        `,
        (results: { countries: [] }) => {
            const processedResults: any[] = [];
            R.sortWith([R.ascend(R.prop('name'))])(results.countries).forEach((res: any) => {
                processedResults.push(new ConditionValue(`${res.id}`, res.name));
            });
            return processedResults;
        },
    );

    static readonly CONTINENT = new Field(
        'org.continent',
        'Organisation continent',
        FieldType.SELECT,
        'continent',
        'continents',
        'from',
        'Europe',
        `continents {
                id
                name
            }
        `,
        (results: { continents: [] }) => {
            const processedResults: any[] = [];
            R.sortWith([R.ascend(R.prop('name'))])(results.continents).forEach((res: any) => {
                processedResults.push(new ConditionValue(`${res.id}`, res.name));
            });
            return processedResults;
        },
    );

    static readonly AGE = new Field('age', 'User age', FieldType.INTEGER, 'age', 'ages', 'with', '36');

    static readonly EMAIL = new Field(
        'email',
        'User email',
        FieldType.EMAIL,
        'email',
        'emails',
        'with',
        'john.doe@example.org',
    );

    static readonly ORGANISATION_TYPE = new Field(
        'org.type',
        'Organisation type',
        FieldType.SELECT,
        'organisation type',
        'organisation types',
        'with',
        'generic',
        `organisationTypes {
                id,
                name
            }
        `,
        (results: { organisationTypes: [] }) => {
            const processedResults: any[] = [];
            results.organisationTypes.forEach((res: { id: string; name: string }) => {
                processedResults.push(new ConditionValue(res.id, res.name));
            });

            return processedResults;
        },
        'organisation',
    );

    readonly key: string;

    readonly fieldType: FieldType;

    readonly singularPolicyLabel: string;

    readonly pluralPolicyLabel: string;

    readonly prefix: string;

    readonly displayLabel: string;

    readonly sampleValue: string;

    readonly query: string | undefined;

    readonly resultsProcessing: any | undefined;

    readonly featureKey: string | undefined;

    values: ConditionValue[] | undefined;

    valuesMap: any;

    private static firstTime = true;

    private static isFeatureEnabled: any;

    constructor(
        key: string,
        displayLabel: string,
        fieldType: FieldType,
        singularPolicyLabel: string,
        pluralPolicyLabel: string,
        prefix: string,
        sampleValue: string,
        query?: string,
        resultsProcessing?: any,
        featureKey?: string,
    ) {
        this.key = key;
        this.fieldType = fieldType;
        this.singularPolicyLabel = singularPolicyLabel;
        this.pluralPolicyLabel = pluralPolicyLabel;
        this.prefix = prefix;
        this.displayLabel = displayLabel;
        this.sampleValue = sampleValue;
        this.query = query;
        this.resultsProcessing = resultsProcessing;
        this.valuesMap = {};
        this.featureKey = featureKey;
    }

    static all() {
        const allPossible = [
            // Field.COUNTRY,
            // Field.CONTINENT,
            // Field.AGE,
            Field.EMAIL,
            // Field.ORGANISATION_TYPE,
        ];

        const allAvailable = [];
        for (let i = 0; i < allPossible.length; i++) {
            const field = allPossible[i];
            if (!field.featureKey || this.isFeatureEnabled(field.featureKey)) {
                allAvailable.push(field);
            }
        }

        return allAvailable;
    }

    static initialize(isFeatureEnabled: any): Promise<void> {
        this.isFeatureEnabled = isFeatureEnabled;
        return new Promise((resolve, reject) => {
            const queries: string[] = [];
            Field.all().forEach((field: Field) => {
                if (field.query) {
                    queries.push(field.query);
                }
            });
            if (queries.length > 0) {
                const { onResult, onError, error, result } = useQuery(
                    gql`{
                        ${queries.join('\n')}
                    }`,
                    {},
                );
                onResult(() => {
                    const results = useResult(result, null, (data: any) => data);
                    const resultItems: any = results.value ? results.value : {};
                    for (let i = 0; i < Field.all().length; i++) {
                        const field = Field.all()[i];
                        if (field.query) {
                            field.values = field.resultsProcessing ? field.resultsProcessing(resultItems) : resultItems;
                            field.valuesMap = {};
                            if (field.values) {
                                field.values.forEach((val: any) => {
                                    const children: ConditionValue[] = [];
                                    if (val.children && val.children.length > 0) {
                                        val.children.forEach((child: ConditionValue) => {
                                            children.push(new ConditionValue(child.id, child.label));
                                        });
                                    }
                                    field.valuesMap[`${val.id}`] = new ConditionValue(`${val.id}`, val.label, children);
                                });
                            }
                        }
                    }
                    resolve();
                });
                onError(() => {
                    reject(new Error(`Unable to load field values: ${error}`));
                });
            } else {
                resolve();
            }
            Field.firstTime = false;
        });
    }

    static find(key: string): Field | null {
        for (let i = 0; i < Field.all().length; i++) {
            const field = Field.all()[i];
            if (field.key === key) {
                return field;
            }
        }

        return null;
    }

    isInteger(): boolean {
        return this.fieldType === FieldType.INTEGER;
    }

    isString(): boolean {
        return this.fieldType === FieldType.STRING;
    }

    isSelect(): boolean {
        return this.fieldType === FieldType.SELECT;
    }

    getValues(): Promise<ConditionValue[] | undefined> {
        return new Promise((resolve, reject) => {
            if (Field.firstTime) {
                this.fetchValues()
                    .then(() => {
                        resolve(this.values);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } else {
                resolve(this.values);
            }
        });
    }

    getValue(rawIds: string[] | string | number[] | number): ConditionValue[] {
        let ids: string[] | string = [];
        if (!R.is(Array, rawIds)) {
            ids = this.isSelect() ? [`${rawIds}`] : `${rawIds}`;
        } else {
            const tempIds: string[] = [];
            (rawIds as []).forEach((id) => {
                tempIds.push(`${id}`);
            });
            ids = tempIds;
        }

        if (!this.isSelect()) {
            if (R.is(Array, ids)) {
                const result: ConditionValue[] = [];
                (ids as []).forEach((id) => {
                    result.push(new ConditionValue(id, id));
                });
                return result;
            }
            return [new ConditionValue(ids as string, ids as string)];
        }
        if (this.values && this.values.length > 0) {
            let result = [];
            for (let i = 0; i < this.values.length; i++) {
                const val: ConditionValue = this.values[i];
                if (ids.includes(val.id)) {
                    result.push(this.valuesMap[val.id]);
                }
                if (R.hasIn('children', val) && val.children && val.children.length > 0) {
                    let allChildrenIncluded = true;
                    const childrenValues = [];
                    for (let c = 0; c < val.children.length; c++) {
                        const child = val.children[c];
                        if (ids.includes(child.id)) {
                            childrenValues.push(this.valuesMap[`${val.id}`].childMap[child.id]);
                        } else {
                            allChildrenIncluded = false;
                        }
                    }
                    if (allChildrenIncluded) {
                        result.push(val);
                    } else {
                        result = [...result, ...childrenValues];
                    }
                }
            }
            return result;
        }
        return [];
    }

    findParentForChildId(id: string) {
        if (!this.values || this.values.length === 0) {
            return null;
        }
        for (let i = 0; i < this.values.length; i++) {
            const parent: ConditionValue = this.values[i];
            if (parent.children && parent.children.length > 0) {
                for (let c = 0; c < parent.children.length; c++) {
                    const child = parent.children[c];
                    if (child.id === id) {
                        return parent;
                    }
                }
            }
        }

        return null;
    }

    getSiblingsForId(id: string) {
        if (!this.values || this.values.length === 0) {
            return [];
        }
        for (let i = 0; i < this.values.length; i++) {
            const parent: ConditionValue = this.values[i];
            if (parent.id === id) {
                const result = [...this.values];
                result.splice(i, 1);
                return result;
            }
            if (parent.children && parent.children.length > 0) {
                for (let c = 0; c < parent.children.length; c++) {
                    const child = parent.children[c];
                    if (child.id === id) {
                        const result = [...parent.children];
                        result.splice(c, 1);
                        return result;
                    }
                }
            }
        }

        return [];
    }

    private fetchValues(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (Field.firstTime) {
                const queries: string[] = [];
                Field.all().forEach((field: Field) => {
                    if (field.query) {
                        queries.push(field.query);
                    }
                });
                const { onResult, onError, error, result } = useQuery(
                    gql`{
                        ${queries.join('\n')}
                    }`,
                    {},
                );
                onResult(() => {
                    const results = useResult(result, null, (data: any) => data);
                    const resultItems: any = results.value ? results.value : {};
                    for (let i = 0; i < Field.all().length; i++) {
                        const field = Field.all()[i];
                        if (field.query) {
                            field.values = field.resultsProcessing ? field.resultsProcessing(resultItems) : resultItems;
                        }
                    }

                    resolve();
                });
                onError(() => {
                    reject(new Error(`Unable to load field '${this.displayLabel}' with error: ${error.value}`));
                });
                Field.firstTime = false;
            } else {
                resolve();
            }
        });
    }
}
